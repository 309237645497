import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { ProviderIcon } from "@toolkit/ui";
import { BranchUsersManagement } from "pages/BranchUsers";
import { providersManagementPaths, providersManagementRoute } from "pages/ProvidersManagement/constants/providers-management-paths";
import { BranchesInfo, EditBranchPage, NewBranchPage, VendorsFormEditPage, VendorsList } from "pages/ProvidersManagement/index";
import { VendorsFormNewPage } from "pages/ProvidersManagement/new";
import { ProviderUsersManagement } from "pages/ProviderUsers";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const providersManagementRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({
  navigate,
  t,
}): RouteItem => {
  const canManageBranches = hasPermission(PermissionEnum.ManageBranches);
  return {
    id: "providers-lists",
    text: t("Health Providers", { ns: "admin" }),
    icon: <ProviderIcon />,
    route: providersManagementRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageVendors),
    subItems: [
      {
        id: "providers-management-list-route",
        text: t("Health Providers", { ns: "admin" }),
        route: providersManagementPaths.listPath.route,
        fullPath: providersManagementPaths.listPath.fullPath,
        element: <VendorsList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "providers-management-new-route",
        text: t("New Provider", { ns: "admin" }),
        route: providersManagementPaths.newPath.route,
        fullPath: providersManagementPaths.newPath.fullPath,
        hidden: true,
        element: <VendorsFormNewPage />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "providers-management-edit-route",
        text: t("Edit Provider", { ns: "admin" }),
        route: providersManagementPaths.editPath.route,
        fullPath: providersManagementPaths.editPath.fullPath,
        hidden: true,
        element: <VendorsFormEditPage />,
      },
      {
        id: "providers-management-users-route",
        text: t("Provider Users", { ns: "admin" }),
        route: providersManagementPaths.usersPath.route,
        fullPath: providersManagementPaths.usersPath.fullPath,
        hidden: true,
        element: <ProviderUsersManagement />,
      },
      {
        id: "providers-management-branches-route",
        text: t("Provider Branches", { ns: "admin" }),
        route: providersManagementPaths.branchesPath.route,
        fullPath: providersManagementPaths.branchesPath.fullPath,
        hidden: true,
        element: <BranchesInfo />,
      },
      {
        id: "providers-management-branches-new-route",
        text: t("New Branch", { ns: "admin" }),
        route: providersManagementPaths.branchesNewPath.route,
        fullPath: providersManagementPaths.branchesNewPath.fullPath,
        hidden: true,
        isProhibited: !canManageBranches,
        element: <NewBranchPage />,
      },
      {
        id: "providers-management-branches-edit-route",
        text: t("Edit Branch", { ns: "admin" }),
        route: providersManagementPaths.branchesEditPath.route,
        fullPath: providersManagementPaths.branchesEditPath.fullPath,
        hidden: true,
        isProhibited: !canManageBranches,
        element: <EditBranchPage />,
      },
      {
        id: "providers-management-branches-users-route",
        text: t("Branch Users", { ns: "admin" }),
        route: providersManagementPaths.branchesUsersPath.route,
        fullPath: providersManagementPaths.branchesUsersPath.fullPath,
        hidden: true,
        isProhibited: !canManageBranches,
        element: <BranchUsersManagement />,
      },
    ],
  };
};
