var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useHealthParameterUnitsAutocompleteQuery } from "./gql";
export const HealthParameterUnitsAutocomplete = props => {
    const { name, skip, label } = props, rest = __rest(props, ["name", "skip", "label"]);
    const { t } = useTranslation("domains");
    const { data, loading } = useHealthParameterUnitsAutocompleteQuery({
        skip: props.disabled || skip,
    });
    const _data = data === null || data === void 0 ? void 0 : data.getHealthParameterUnits;
    const options = mapToAutocompleteOptions(_data, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, (item === null || item === void 0 ? void 0 : item.arabicDisplay) || (item === null || item === void 0 ? void 0 : item.display)));
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { name: name, label: label || (props.multiple ? t("Units") : t("Unit")), options: options, loading: loading })));
};
