import { hasPermission } from "@health/common";
import { PermissionEnum, ProcessingStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useGridContext } from "@toolkit/ui";
import { useState } from "react";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const useActionsMenuHooks = () => {
    var _a;
    const { t } = useTranslation("domains");
    const [menuActionsAnchorEl, setMenuActionsAnchorEl] = useState(null);
    const { isNew, isEditable, processingStatus } = useAdminManagedListDetailsPageContext();
    const { customTableProps } = useGridContext();
    const isMenuOpened = Boolean(menuActionsAnchorEl);
    const hasData = ((_a = customTableProps === null || customTableProps === void 0 ? void 0 : customTableProps.data) === null || _a === void 0 ? void 0 : _a.length) > 0;
    const hasClone = isNew || !!isEditable;
    const hasImport = isNew || !!isEditable;
    const hasDownload = true;
    const hasApproveReject = processingStatus === ProcessingStatus.UnderReview;
    const hasPublish = processingStatus === ProcessingStatus.Approved;
    const isDraft = processingStatus === ProcessingStatus.Draft;
    const hasReassign = hasPermission(PermissionEnum.ManageCodeSystemEditorReviewers);
    const handleOpenMenuToAnchor = (event) => {
        setMenuActionsAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuActionsAnchorEl(null);
    };
    return {
        isMenuOpened,
        menuActionsAnchorEl,
        hasClone,
        hasImport,
        hasDownload,
        hasApproveReject,
        hasPublish,
        isDraft,
        hasData,
        hasReassign,
        t,
        handleOpenMenuToAnchor,
        handleCloseMenu,
    };
};
