import { jsx as _jsx } from "react/jsx-runtime";
import { useReportAnError } from "../../../../shared/hooks";
import { SublistItemUpsertForm } from "../../../Sublists/forms";
import { SublistItemListDocument, useSublistItemCreateMutation } from "../../../Sublists/gql";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, useToasts } from "@toolkit/ui";
import { FormProvider, useForm } from "react-hook-form";
import { formatMessageErrors } from "@toolkit/apollo";
export const SublistItemCreateForm = props => {
    const { id } = props;
    const { t } = useTranslation("domains");
    const { addToast } = useToasts();
    const { reportGraphQlErrors } = useReportAnError();
    const [fetchSublistItemCreateMutation, { loading: isSubmitting }] = useSublistItemCreateMutation({
        refetchQueries: [SublistItemListDocument],
    });
    const methods = useForm({
        mode: "all",
    });
    const addItem = (input) => {
        fetchSublistItemCreateMutation({
            variables: {
                subListId: id,
                input: {
                    code: input.code,
                    display: input.display,
                },
            },
            onCompleted: e => {
                var _a, _b, _c;
                if ((_b = (_a = e.addSubListItem) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) {
                    addToast(t("Failed to add item") + "\n" + formatMessageErrors((_c = e.addSubListItem) === null || _c === void 0 ? void 0 : _c.errors), {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    return;
                }
                methods.reset();
                addToast(t("Item added successfully"), {
                    appearance: "success",
                    autoDismiss: true,
                });
            },
            onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Failed to add sublist item"))(graphQLErrors),
        });
    };
    const handleAddListItem = methods.handleSubmit(addItem);
    return (_jsx(FormCard, Object.assign({ loading: false, title: t("Add new item"), doYouHaveData: true }, { children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsx(SublistItemUpsertForm, { isSubmitting: isSubmitting, onHandleSubmit: handleAddListItem, submitButtonText: t("Add") }) })) })));
};
