import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import useToggle from "shared/hooks/useToggle";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, GridContext, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { hasPermission } from "@health/common";
import { FileDownload, FileImportDialog } from "@health/domains";
import { BulkTemplateCode, PermissionEnum } from "@health/queries/types";
import {
  networkFieldBreadcrumb,
  networkListBreadcrumb,
  networkTitleBreadcrumb,
} from "pages/HealthProgramNetworks/constants/network-breadcrumbs";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { useGetNetworkQuery, useHealthProgramNetworkProvidersQuery } from "../gql";
import { useHealthProgramNetworkProvidersColumns } from "./components/useHealthProgramNetworkProvidersColumns";

export const HealthProgramNetworkProviders: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id!;
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const canManageActions = hasPermission(PermissionEnum.ManageHealthPrograms);

  const { open, handleToggle } = useToggle();

  const handleEditRow = data => {
    navigate(networksPaths.providersEditPath.fullPathWithParams(id, data?.id));
  };

  const handleAddNew = () => {
    navigate(networksPaths.providersNewPath.fullPathWithParams(id));
  };

  const { data } = useGetNetworkQuery({
    variables: {
      filter: {
        ids: [id],
      },
    },
  });

  const healthProgramNetworks = data?.healthProgramNetworks?.edges?.map(item => item?.node)[0];

  const handleCompleted = data => {
    !data?.healthProgramNetworkProviders?.edges?.length && handleToggle();
  };

  useEffect(() => {
    if (healthProgramNetworks?.name) {
      setBreadCrumb({
        title: networkTitleBreadcrumb(t),
        values: [networkListBreadcrumb(t), networkFieldBreadcrumb(healthProgramNetworks?.name)],
      });
    }
  }, [healthProgramNetworks?.name]);

  return (
    <GridProvider
      gridName='healthProgramNetworkProviders'
      columns={useHealthProgramNetworkProvidersColumns({ healthProgramNetworkID: id })}
      query={useHealthProgramNetworkProvidersQuery}
      variables={{
        filter: { healthProgramNetworks: [id] },
      }}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
        isRowEditable: canManageActions,
      }}
      onCompleted={handleCompleted}
    >
      <GridContext.Consumer>
        {({ customTableProps }) => {
          const handleSuccessUpload = () => {
            customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
          };
          return (
            <PageWrapper
              filters={<FilterGrid />}
              actions={
                <>
                  <TableSettingComponent />
                  {canManageActions && (
                    <MainListActions hasAddNew onAddNewItem={handleAddNew}>
                      <FileImportDialog
                        title={t("Import Providers")}
                        subTitle={t("Upload Providers list you want to get")}
                        isOpen={open}
                        onToggle={handleToggle}
                        onSuccessUpload={handleSuccessUpload}
                        templateCode={BulkTemplateCode.HealthProgramNetworkProvider}
                      />

                      <FileDownload templateCode={BulkTemplateCode.HealthProgramNetworkProvider} />
                    </MainListActions>
                  )}
                </>
              }
            >
              <TableGrid />
            </PageWrapper>
          );
        }}
      </GridContext.Consumer>
    </GridProvider>
  );
};
