import { jsx as _jsx } from "react/jsx-runtime";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { GridProvider } from "../../../../shared/components";
import { useGetPrescriptionsOrdersQuery } from "../../../OrdersTracking/gql/queries";
import { OrderSourcesEnum } from "@health/queries/types";
import { usePrescriptionsOrdersTrackingColumns } from "./usePrescriptionsOrdersTrackingColumns";
export const PrescriptionsOrdersTrackingPage = ({ isAdmin }) => {
    return (_jsx(GridProvider, Object.assign({ gridName: 'prescriptions-orders', columns: usePrescriptionsOrdersTrackingColumns({ isAdmin }), query: useGetPrescriptionsOrdersQuery, filterInput: {
            source: [OrderSourcesEnum.PrescriptionDispense, OrderSourcesEnum.PrescriptionRefill],
        }, variables: { isAdmin }, hasTableSetting: true }, { children: _jsx(PageWrapper, Object.assign({ filters: _jsx(FilterGrid, {}), actions: _jsx(TableSettingComponent, {}) }, { children: _jsx(TableGrid, {}) })) })));
};
