export const formatStringByJoin = (names: string[], separator?: string): string => {
  return names
    .filter(Boolean)
    .join(separator || " ")
    .trim();
};

type Name = string | null;
export const formatUserNameFull = <T extends { firstName?: Name; lastName?: Name }>(user?: T) => {
  return user ? `${user.firstName || ""} ${user.lastName || ""}`.trim() : "";
};
