import { jsx as _jsx } from "react/jsx-runtime";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionConditionGuidedCareTemplatesAutocompleteQuery, } from "../../../Decisions/gql";
export const DecisionConditionGuidedCareTemplatesAutocomplete = props => {
    const { name } = props;
    const { t } = useTranslation("domains");
    const { data, isLoading, hasMoreData, refetch, fetchMoreData } = useRelayStyleConnection({
        dataAccessor: "healthProgramTemplates",
        useQuery: useDecisionConditionGuidedCareTemplatesAutocompleteQuery,
    });
    const options = mapToAutocompleteOptions(data, "id", "display");
    const onSearchInputChange = (value) => {
        refetch({
            first: 10,
            filter: {
                name: value,
            },
        });
    };
    return (_jsx(FormAutocomplete, { name: name, label: t("Template"), placeholder: t("Template"), options: options, hasMore: hasMoreData, loading: isLoading, fetchMoreData: fetchMoreData, onSearchInput: onSearchInputChange }));
};
