import { NavigateFunction } from "react-router-dom";
import { TFunction } from "@toolkit/i18n";
import { HealthProgramMemberEditPage, HealthProgramMemberNewPage } from "pages/HealthProgramMembers";
import { membersPaths, membersRoute } from "pages/Members/constants/members-paths";
import { UserMemberShips } from "pages/Members/UserMemberShips";
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";

export const membersRoutes = ({ navigate, t }: { navigate: NavigateFunction; t: TFunction }) => {
  return [
    {
      id: "members-routes",
      text: t("Members Management", { ns: "admin" }),
      route: membersRoute,
      isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
      subItems: [
        {
          id: "members-main-route",
          text: t("Members Management", { ns: "admin" }),
          route: membersPaths.listPath.route,
          fullPath: membersPaths.listPath.fullPath,
          element: <UserMemberShips />,
          onClick: (route: string) => navigate(route),
        },
        {
          id: "members-new-route",
          text: t("Members New", { ns: "admin" }),
          route: membersPaths.newPath.route,
          fullPath: membersPaths.newPath.fullPath,
          hidden: true,
          element: <HealthProgramMemberNewPage />,
          onClick: (route: string) => navigate(route),
        },
        {
          id: "members-edit-route",
          text: t("Members Edit", { ns: "admin" }),
          route: membersPaths.editPath.route,
          fullPath: membersPaths.editPath.fullPath,
          hidden: true,
          element: <HealthProgramMemberEditPage />,
          onClick: (route: string) => navigate(route),
        },
      ],
    },
  ];
};
