import { EnumLike, ZodNativeEnum, ZodObject, ZodRawShape, ZodString, z } from "zod";
import { IFilter } from "../components";
import { IAutocompleteEnumFilter, IAutocompleteLabelBy, IAutocompleteOption, IAutocompleteQueryFilter } from "../types";
import { get } from "lodash";

export function createZodAutocompleteObject(): ZodObject<{ key: ZodString; label: ZodString; value: ZodString }>;

export function createZodAutocompleteObject<T extends EnumLike>(
  value: T
): ZodObject<{ key: ZodNativeEnum<T>; label: ZodString; value: ZodNativeEnum<T> }>;

export function createZodAutocompleteObject<T extends ZodRawShape>(
  value: ZodObject<T>
): ZodObject<{ key: ZodString; label: ZodString; value: ZodObject<T> }>;

export function createZodAutocompleteObject(value?: EnumLike | ZodObject<any>): any {
  const baseObject = {
    key: z.string(),
    label: z.string(),
  };

  if (!value) {
    return z.object({ ...baseObject, value: z.string() });
  } else if (value instanceof ZodObject) {
    return z.object({ ...baseObject, value });
  } else {
    return z.object({ ...baseObject, value: z.nativeEnum(value) });
  }
}

export const getAutocompleteLabel = <T,>(item: T, labelBy: IAutocompleteLabelBy<T>) => {
  return typeof labelBy === "function" ? labelBy(item) : get(item, labelBy);
};

export const createAutocompleteOption = <T,>(item: T, keyBy: keyof T, labelBy: IAutocompleteLabelBy<T>): IAutocompleteOption<T> => {
  return {
    key: item[keyBy] as string,
    label: getAutocompleteLabel(item, labelBy),
    value: item,
  };
};

export const createAutocompleteStringOption = (item: string, labelBy?: (item: string) => string): IAutocompleteOption<string> => {
  return {
    key: item.toUpperCase(),
    label: labelBy ? labelBy(item) : item,
    value: item,
  };
};

export const mapStringsToAutocompleteOptions = (items: string[], labelBy?: (item: string) => string): IAutocompleteOption<string>[] => {
  if (!items || !items?.length) return [];
  return items.map(item => createAutocompleteStringOption(item, labelBy));
};

export const mapToAutocompleteOptions = <T extends object>(
  items: T[],
  keyBy: keyof T,
  labelBy: IAutocompleteLabelBy<T>
): IAutocompleteOption<T>[] => {
  if (!items || !items?.length) return [];
  return items.map(item => createAutocompleteOption(item, keyBy, labelBy));
};

export const getAutocompleteFilter = <T,>(options: IAutocompleteQueryFilter<T>): IFilter => {
  const { labelBy, backendAccessor, ...rest } = options;

  const getOptionLabel = (option: T): string => {
    return getAutocompleteLabel(option, labelBy!);
  };

  const getValueForBackend = (option: T | T[]) => {
    return getAutocompleteFilterValueForBackend(option, backendAccessor, options?.multiple);
  };

  return {
    ...rest,
    type: "autocomplete" as const,
    getOptionLabel,
    getValueForBackend,
  };
};

const getAutocompleteFilterValueForBackend = <T,>(option: T | T[], dataAccessor: IAutocompleteLabelBy<T>, multiple?: boolean) => {
  if (multiple) {
    return Array.isArray(option) ? option.map(_option => getBackendAccessorValue(_option, dataAccessor)) : undefined;
  } else {
    return getBackendAccessorValue(option as T, dataAccessor);
  }
};

const getBackendAccessorValue = <T,>(option: T, dataAccessor: IAutocompleteLabelBy<T>) => {
  if (typeof dataAccessor === "function") {
    return dataAccessor(option);
  } else {
    return option?.[dataAccessor];
  }
};

export const getAutocompleteEnumFilter = <T,>(options: IAutocompleteEnumFilter<T>): IFilter => {
  return getAutocompleteFilter({
    ...options,
    labelBy: "label",
    backendAccessor: "value",
  });
};
