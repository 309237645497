import { GraphQLError } from "graphql";

export const formatGraphQLError = (graphQLErrors: readonly GraphQLError[]): string => {
  const text = graphQLErrors
    ?.map(error => {
      return `${error.message || ""}`;
    })
    .filter(Boolean)
    .join(", ");
  if (process.env.NODE_ENV === "development") {
    pushToTolgee([
      {
        name: text,
        namespace: "backend-errors",
        translations: { en: text, ar: "" },
        tags: ["auto-translated", "error-message"],
      },
    ]);
  }
  return text;
};

const pushToTolgee = async (
  tolgeeTexts: Array<{
    name: string;
    namespace: string;
    translations: { en: string; ar: string };
    tags: ["auto-translated", "error-message"];
  }>
) => {
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-Key": process.env.TOLGEE_API_KEY!,
    },
    body: JSON.stringify({ keys: tolgeeTexts }),
  };
  try {
    const response = await fetch(`${process.env.TOLGEE_API_URL}/v2/projects/5/keys/import`, options);
    if (response.ok) {
      console.log("✅  Synced with Tolgee");
    } else {
      return Promise.reject("Error syncing with Tolgee");
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const formatMessageErrors = <T extends { field?: string | null; message?: string | null }>(
  errors?: Array<T | null | undefined> | null | undefined
): string => {
  const text = errors?.map(error => `${error?.field ? error?.field + ",\r\n" : ""} ${error?.message || ""}\r\n`)?.join("") || "";

  if (process.env.NODE_ENV === "development") {
    pushToTolgee([
      {
        name: text,
        namespace: "backend-errors",
        translations: { en: text, ar: "" },
        tags: ["auto-translated", "error-message"],
      },
    ]);
  }
  return text;
};
