import { DecisionPlanCategory } from "@health/queries/types";
import { createPathDefinition } from "@toolkit/core";
import { kebabCase } from "lodash";

export const decisionsRoute = `/decisions/:category`;

export const decisionsPaths = createPathDefinition(decisionsRoute, {
  main: "",
  new: "new",
  edit: ":id",
});

const getCategoryRouteAndPaths = (category: DecisionPlanCategory) => {
  const categoryKey = kebabCase(category);
  const route = decisionsPaths.main.fullPathWithParams({ category: categoryKey });

  const paths = createPathDefinition(route, {
    main: "",
    new: "new",
    edit: ":id",
  });

  return { route, paths };
};

export const patientEligibilityRoutes = getCategoryRouteAndPaths(DecisionPlanCategory.PatientEligibility);
export const riskStratificationRoutes = getCategoryRouteAndPaths(DecisionPlanCategory.RiskStratification);
export const labRoutes = getCategoryRouteAndPaths(DecisionPlanCategory.HealthCondition);
export const userRequiredActionRoutes = getCategoryRouteAndPaths(DecisionPlanCategory.UserRequiredAction);
