import { jsx as _jsx } from "react/jsx-runtime";
import { useQueryString, useReportAnError } from "../../../../shared/hooks";
import { SublistItemUpsertForm } from "../../../Sublists/forms";
import { useSublistItemUpdateMutation } from "../../../Sublists/gql";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, PageWrapper, useToasts } from "@toolkit/ui";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { fromBase64 } from "@toolkit/core";
export const SublistItemUpdateContainer = () => {
    const { t } = useTranslation("domains");
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const { reportGraphQlErrors } = useReportAnError();
    const query = useQueryString();
    const [fetchSaveItem, { loading: isSubmitting }] = useSublistItemUpdateMutation();
    const { itemId } = useParams();
    const item = JSON.parse(fromBase64(query.get("item") || ""));
    const methods = useForm({
        mode: "all",
    });
    const addItem = (input) => {
        fetchSaveItem({
            variables: {
                subListItem: {
                    id: itemId,
                    code: input.code,
                    display: input.display,
                },
            },
            onCompleted: e => {
                var _a, _b, _c;
                if ((_b = (_a = e.updateSubListItem) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) {
                    addToast(t("Failed to update item") + "\n" + formatMessageErrors((_c = e.updateSubListItem) === null || _c === void 0 ? void 0 : _c.errors), {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    return;
                }
                addToast(t("Item updated successfully"), {
                    appearance: "success",
                    autoDismiss: true,
                });
                navigate(-1);
            },
            onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Failed to update sublist item"))(graphQLErrors),
        });
    };
    const handleSaveListItem = methods.handleSubmit(addItem);
    const handleNavigation = () => {
        navigate(-1);
    };
    return (_jsx(PageWrapper, Object.assign({ actions: _jsx(FormActions, { hasSave: true, hasCancel: true, onSave: handleSaveListItem, onNavigation: handleNavigation, loadingIndicators: { save: isSubmitting }, isSaveDisabled: isSubmitting }) }, { children: _jsx(FormCard, Object.assign({ loading: false, title: t("Edit item"), doYouHaveData: true }, { children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsx(SublistItemUpsertForm, { onHandleSubmit: handleSaveListItem, defaults: item }) })) })) })));
};
