import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import useToggle from "shared/hooks/useToggle";
import { useHealthProgramMemberListsQuery } from "../HealthProgramMemberLists";
import { useHealthProgramMemberUpdateMutation } from "../HealthProgramMembers";

export const useUserMemberShipsHooks = memberId => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const { open, handleToggle } = useToggle();

  const {
    data,
    loading,
    refetch: refetchMemberLists,
    fetchMore,
  } = useHealthProgramMemberListsQuery({
    variables: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
    skip: !open,
  });

  const {
    data: members,
    loading: loadingMemberLists,
    refetch,
  } = useHealthProgramMemberListsQuery({
    variables: {
      first: 100,
      filter: {
        members: [memberId],
      },
    },

    skip: !open,
  });

  const [fetchUpdateMemberLists, { loading: loadingUpdate }] = useHealthProgramMemberUpdateMutation({
    onCompleted: data => {
      const errors = data?.healthProgramMemberUpdate?.entityErrors;
      if (errors?.length === 0) {
        succeeded(t("Member Lists updated successfully"));
        handleToggle();
        refetch();
      } else {
        failed(formatMessageErrors(errors));
        handleToggle();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      handleToggle();
    },
    notifyOnNetworkStatusChange: true,
  });

  const healthProgramMemberLists = data?.healthProgramMemberLists?.edges?.map(item => item?.node);
  const membersLists = members?.healthProgramMemberLists?.edges?.map(item => item?.node);

  const pageInfo = data?.healthProgramMemberLists?.pageInfo;

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            healthProgramMemberLists: {
              ...fetchMoreResult.healthProgramMemberLists,
              edges: [...(prev?.healthProgramMemberLists?.edges || []), ...(fetchMoreResult?.healthProgramMemberLists?.edges || [])],
            },
          });
        },
      });
    }
  };

  const handleUpdate = (data, input) => {
    fetchUpdateMemberLists({
      variables: {
        id: memberId,
        input: {
          ...input,
          healthProgramMemberLists: data.healthProgramMemberLists?.map(item => item.id),
        },
      },
    });
  };

  const handleSearchInput = (value: string) => {
    refetchMemberLists({ filter: { name_Icontains: value, isActive: true }, first: 10 });
  };

  return {
    open,
    pageInfo,
    membersLists,
    healthProgramMemberLists,
    loadingUpdate,
    loading: loading || loadingMemberLists,
    handleToggle,
    fetchMoreData,
    handleUpdate,
    handleSearchInput,
  };
};
