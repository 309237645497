import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetPrescriptionsOrdersDocument = gql `
    query getPrescriptionsOrders($filter: OrderFilterInput, $first: Int, $after: String, $before: String, $last: Int, $sortBy: OrderSortingInput, $isAdmin: Boolean!) {
  orders(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
  ) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        created
        number
        status
        type
        branch {
          id
          name
          nameAr
          vendor @include(if: $isAdmin) {
            id
            name
            nameAr
          }
        }
        source
        total {
          gross {
            amount
            currency
          }
        }
        children {
          number
          status
          type
          branch {
            name
            nameAr
            contactNumber
          }
        }
        prescription {
          id
          patientIdentifier
          referenceNumber
          patient {
            firstName
            lastName
            contactNumber
            email
          }
          medications {
            id
            code
            refills
            display
            quantity
            instructions
            arabicInstructions
          }
          dispenseStatus
          orders(first: 20) {
            totalCount
            pageInfo {
              ...PageInfoFragment
            }
            edges {
              node {
                id
                number
                __typename
              }
            }
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;
/**
 * __useGetPrescriptionsOrdersQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionsOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionsOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionsOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useGetPrescriptionsOrdersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetPrescriptionsOrdersDocument, options);
}
export function useGetPrescriptionsOrdersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetPrescriptionsOrdersDocument, options);
}
