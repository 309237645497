import { HealthProgramMemberListSortingField, H_OrderDirection, PermissionEnum } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { membersTitleBreadcrumb } from "pages/HealthProgramMemberLists/constants/member-list-breadcrumbs";
import { memberListPaths } from "pages/HealthProgramMemberLists/constants/member-list-paths";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useHealthProgramMemberListsQuery } from "../gql";
import { useHealthProgramMemberListsColumns } from "./components/useHealthProgramMemberListsColumns";
import { hasPermission } from "@health/common";

export const HealthProgramMemberLists: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("admin");
  const canManageActions = hasPermission(PermissionEnum.ManageHealthPrograms);

  const handleEditRow = data => {
    navigate(memberListPaths.editPath.fullPathWithParams(data?.id));
  };

  const handleAddNew = () => {
    navigate(memberListPaths.newPath.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({
      title: membersTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='healthProgramMemberLists'
      columns={useHealthProgramMemberListsColumns({ canManageActions })}
      query={useHealthProgramMemberListsQuery}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramMemberListSortingField.Created,
        },
      }}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
        isRowEditable: canManageActions,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            {canManageActions && <MainListActions hasAddNew titleAddButton={t("Add New")} onAddNewItem={handleAddNew} />}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
