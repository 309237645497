import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HealthParametersAutocomplete, DecisionPlansAutocomplete } from "@health/autocompletes";
import { priorityOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
export const DecisionInformationForm = props => {
    const { isScript, category, healthParameter, isUpdateMode } = props;
    const { t } = useTranslation("domains");
    const gridSize = healthParameter ? 3 : 4;
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: gridSize }, { children: _jsx(FormTextField, { name: "name", label: t("Name"), placeholder: t("Name") }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: gridSize }, { children: _jsx(DecisionPlansAutocomplete, { name: "decisionPlan", disabled: isUpdateMode, filter: { categories: [category] } }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: gridSize }, { children: _jsx(FormAutocomplete, { name: "priority", label: t("Priority"), options: priorityOptions }) })), !!healthParameter && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: _jsx(HealthParametersAutocomplete, { name: "healthParameter", disabled: true }) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 2 }, { children: _jsx(FormSwitch, { name: "isActive", label: t("Is Active") }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 2 }, { children: _jsx(FormSwitch, { name: "isScript", label: t("Is Script") }) })), isScript && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormTextField, { name: "script", label: t("Script"), placeholder: t("Script"), rows: 7, multiline: true }) })))] })));
};
