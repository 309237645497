import { TFunction } from "@toolkit/i18n";
import { z, ZodIssueCode, ZodIssueOptionalMessage } from "zod";

export const getZodErrorMapMessages = (t: TFunction): z.ZodErrorMap => {
  // eslint-disable-next-line max-statements
  return (issue: ZodIssueOptionalMessage, ctx: z.ErrorMapCtx) => {
    switch (issue.code) {
      case ZodIssueCode.invalid_type:
        return { message: t("Required") };
      case ZodIssueCode.too_small:
        {
          if (issue.type === "number") {
            return { message: `${t("Minimum value is")} ${issue.minimum}` };
          } else if (issue.type === "string") {
            return { message: `${t("Minimum field length is")} ${issue.minimum}` };
          } else if (issue.type === "date") {
            return { message: `${t("Minimum date is")} ${issue.minimum}` };
          } else if (issue.type === "array") {
            return { message: `${t("Minimum array length is")} ${issue.minimum}` };
          }
        }
        return { message: t(ctx.defaultError) };

      case ZodIssueCode.too_big: {
        if (issue.type === "number") {
          return { message: `${t("Maximum value is")} ${issue.maximum}` };
        } else if (issue.type === "string") {
          return { message: `${t("Maximum field length is")} ${issue.maximum}` };
        } else if (issue.type === "date") {
          return { message: `${t("Maximum date is")} ${issue.maximum}` };
        } else if (issue.type === "array") {
          return { message: `${t("Maximum array length is")} ${issue.maximum}` };
        }
        return { message: t(ctx.defaultError) };
      }
      default:
        return { message: t(ctx.defaultError) };
    }
  };
};
