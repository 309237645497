import { useCallback, useState } from "react";

export const useToggle = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen(prevState => !prevState);
  }, []);

  return { open, handleToggle };
};
