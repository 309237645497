import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const medicalMessageAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const MedicalMessageAutocompleteSchemas = {
    medicalMessageAutocomplete,
    medicalMessageAutocompleteOptional: medicalMessageAutocomplete.nullable().optional(),
};
