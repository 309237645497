import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { getCodeSystemProcessingStatusColor } from "../../shared/utils";
import { DotBadge } from "./Badge/DotBadge.components";
export const AMLStatusSummary = ({ processingStatus }) => {
    const { t } = useTranslation("domains");
    const theme = useTheme();
    return !processingStatus ? null : (_jsxs(Box, Object.assign({ display: 'inline' }, { children: [_jsxs(Typography, Object.assign({ display: 'inline', fontSize: theme.mixins.fonts.fontSize.sm }, { children: [t("List Status"), ": \u00A0 \u00A0"] })), _jsx(Typography, Object.assign({ color: getCodeSystemProcessingStatusColor(processingStatus, theme.palette), display: 'inline', marginRight: 1, fontSize: theme.mixins.fonts.fontSize.sm }, { children: _jsx(DotBadge, { processingStatus: processingStatus }) }))] })));
};
