import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import {
  subscriptionsManagementPaths,
  subscriptionsManagementRoute,
} from "pages/SubscriptionsMangment/constants/subscriptions-management-paths";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import SubscriptionPlansForm from "./form/SubscriptionsForm.screen";
import SubscriptionsListPage from "./list";
import { i18n } from "@toolkit/i18n";

export const subscriptionsManagementRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  enabled,
}): RouteItem => {
  return {
    id: "subscriptions-management-routes",
    text: i18n.t("Subscriptions Management", { ns: "admin" }),
    route: subscriptionsManagementRoute,
    isProhibited: !(enabled && hasPermission(PermissionEnum.ManageSubscriptions)),
    hidden: !enabled,
    subItems: [
      {
        id: "subscriptions-management-list-route",
        text: i18n.t("Subscriptions", { ns: "admin" }),
        route: subscriptionsManagementPaths.listPath.route,
        fullPath: subscriptionsManagementPaths.listPath.fullPath,
        element: <SubscriptionsListPage />,
        hidden: !enabled,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "subscriptions-management-new-route",
        text: i18n.t("New Subscription", { ns: "admin" }),
        route: subscriptionsManagementPaths.newPath.route,
        fullPath: subscriptionsManagementPaths.newPath.fullPath,
        element: <SubscriptionPlansForm />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "subscriptions-management-edit-route",
        text: i18n.t("Edit Subscription", { ns: "admin" }),
        route: subscriptionsManagementPaths.editPath.route,
        fullPath: subscriptionsManagementPaths.editPath.fullPath,
        element: <SubscriptionPlansForm />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
