/* eslint-disable max-lines */
import { hasAnyPermission, hasPermission } from "@health/common";
import { FeatureEnum, IsFeatureEnabledType } from "@health/hooks";
import { PermissionEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { ProductIcon } from "@toolkit/ui";
import { healthPackagesRoutes } from "pages/HealthPackages/route";
import { subscriptionsManagementRoutes } from "pages/SubscriptionsMangment/route";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceRoute } from "./marketplace-paths.constants";
import { marketplaceOrdersTrackingRoutes } from "pages/MarketplaceOrderTracking";
import { productRoutes } from "pages/MarketplaceProducts";
import { healthPackageCategoriesRoutes } from "pages/MarketplaceHealthPackageCategories";
import { marketplaceOrdersInvoicesRoutes } from "pages/MarketplaceOrderInvoices";

export const marketplaceRoutes: (props: { navigate: NavigateFunction; isFeatureEnabled: IsFeatureEnabledType }) => RouteItem = ({
  navigate,
  isFeatureEnabled,
}) => {
  const canTrackOrders = hasPermission(PermissionEnum.ManageMarketplaceOrders);
  const canViewMarketplaceMenu =
    hasAnyPermission([
      PermissionEnum.ManageProducts,
      PermissionEnum.ManageBrands,
      PermissionEnum.ManageProductCategories,
      PermissionEnum.ManageHealthPackages,
      PermissionEnum.ManageHealthPackageCategories,
    ]) || canTrackOrders;

  const isMarketplaceRootHidden = !(isFeatureEnabled([FeatureEnum.HealthPackage, FeatureEnum.Marketplace]) && canViewMarketplaceMenu);
  const isMarketplaceItemsEnabled = isFeatureEnabled([FeatureEnum.Marketplace]);
  const isHealthPackageItemEnabled = isFeatureEnabled([FeatureEnum.HealthPackage]);

  return {
    id: "marketplace",
    text: i18n.t("Marketplace", { ns: "admin" }),
    icon: <ProductIcon />,
    isProhibited: isMarketplaceRootHidden,
    hidden: isMarketplaceRootHidden,
    route: marketplaceRoute,
    subItems: [
      productRoutes({ navigate, enabled: isMarketplaceItemsEnabled }),
      healthPackagesRoutes({ navigate, enabled: isHealthPackageItemEnabled }),
      healthPackageCategoriesRoutes({ navigate, enabled: isHealthPackageItemEnabled }),
      subscriptionsManagementRoutes({
        navigate,
        enabled: isMarketplaceItemsEnabled,
      }),
      marketplaceOrdersTrackingRoutes({ navigate }),
      marketplaceOrdersInvoicesRoutes({ navigate }),
    ],
  };
};
