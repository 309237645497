import { getMediaLink } from "@health/common";
import { MarketplaceApprovalStatus } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CheckMarkIcon, CustomDialog, Divider, Grid, IconButton, MDTextEditor, TextField, useAddToast } from "@toolkit/ui";
import { useHealthPackageApproveMutation } from "pages/HealthPackages/gql";
import { FC } from "react";
import useToggle from "shared/hooks/useToggle";
import { ellipseName } from "utils";
import { HealthPackagesQueryNode } from "../../types";
import { useHealthPackageStyles } from "./PreviewHealthPackages.styles";

export const ApproveHealthPackage: FC<{ row: HealthPackagesQueryNode }> = ({ row }) => {
  const { t } = useTranslation("admin");
  const { classes } = useHealthPackageStyles();

  const { handleToggle, open } = useToggle();
  const { failed, succeeded } = useAddToast();
  const [healthPackageApproveMutation, { loading: isLoading }] = useHealthPackageApproveMutation({
    onCompleted: () => {
      handleToggle();
      succeeded(t("Health package approved!"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: cache => {
      const normalizedId = cache.identify({ id: row?.id, __typename: "MarketplaceHealthPackage" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleApproveHealthPackage = () => {
    healthPackageApproveMutation({
      variables: {
        healthPackageApproveId: row?.id,
      },
    });
  };

  const isPendingApproval = row?.approvalStatus === MarketplaceApprovalStatus.PendingApproval;
  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Approve Health Package"),
        onClose: handleToggle,
      }}
      button={
        <IconButton disabled={!isPendingApproval || isLoading || !row?.updatable} onClick={handleToggle}>
          <CheckMarkIcon color='success' />
        </IconButton>
      }
      DialogActionsProps={{
        children: (
          <Button color='success' onClick={handleApproveHealthPackage} disabled={isLoading}>
            {t("Approve")}
          </Button>
        ),
      }}
      maxWidth='md'
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField fullWidth value={ellipseName(row?.name, 40)} disabled label={t("Health Package Name")} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth value={ellipseName(row?.nameAr, 40)} disabled label={t("Health Package Arabic Name")} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth value={ellipseName(row?.shortDescription, 40)} disabled label={t("Short Description")} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth value={ellipseName(row?.shortDescriptionAr, 40)} disabled label={t("Arabic Short Description")} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth value={row?.targetFromAge} disabled label={t("Target From Age")} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth value={row?.targetToAge} disabled label={t("Target To Age")} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth value={`${row?.price}`} disabled label={t("Price")} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth value={pickLocalizedValue(row?.category?.name!, row?.category?.nameAr!)} disabled label={t("Category")} />
        </Grid>
        <Grid item xs={6}>
          <MDTextEditor label={t("Description")} value={row?.description!} readonly />
        </Grid>
        <Grid item xs={6} mb={2}>
          <MDTextEditor label={t("Arabic Description")} value={row?.descriptionAr!} readonly />
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid container spacing={2} marginLeft='3px'>
          <Grid item mt={2}>
            <img
              className={classes.imageListItem}
              height={150}
              width={"300px"}
              loading='lazy'
              src={getMediaLink(decodeURI(row?.mainImageUrl!))}
              alt={"preview"}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
