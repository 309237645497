import { TFunction } from "@toolkit/i18n";
import {
  AdminSublistCreateContainer,
  AdminSublistItemUpdateContainer,
  AdminSublistListContainer,
  AdminSublistUpdateContainer,
} from "../containers";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { subListsPaths, subListsRoute } from "./SublistsPaths";

export const subListsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "sub-lists-routes",
    text: t("Sub Lists Management", { ns: "admin" }),
    route: subListsRoute,
    subItems: [
      {
        id: "sub-list-list-route",
        text: "",
        route: subListsPaths.main.route,
        fullPath: subListsPaths.main.fullPath,
        element: <AdminSublistListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "sub-list-new-route",
        text: "",
        route: subListsPaths.new.route,
        fullPath: subListsPaths.new.fullPath,
        element: <AdminSublistCreateContainer />,
        hidden: true,
      },
      {
        id: "sub-list-update-route",
        text: "",
        route: subListsPaths.edit.route,
        fullPath: subListsPaths.edit.fullPath,
        element: <AdminSublistUpdateContainer />,
        hidden: true,
      },
      {
        id: "sub-list-item-update-route",
        text: "",
        route: subListsPaths.editItem.route,
        fullPath: subListsPaths.editItem.fullPath,
        element: <AdminSublistItemUpdateContainer />,
        hidden: true,
      },
    ],
  };
};
