import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { conditionOperationOptions, conditionOperationOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Button, Container, CustomIcon, FormToggleButtonGroup, Grid } from "@toolkit/ui";
import { ConditionOperation } from "@health/queries/types";
import { DecisionConditionsForm } from "../../../Decisions/forms/DecisionConditions/DecisionConditionsForm";
import { convertDecisionPlanConditionToFormValues } from "../../../Decisions/others/decisionConditionUtils";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDecisionConditionsGroupsFormStyle } from "./useDecisionConditionsGroupsFormStyle";
export const DecisionConditionsGroupsForm = props => {
    const { decisionPlanFields } = props;
    const { t } = useTranslation("domains");
    const { classes, theme } = useDecisionConditionsGroupsFormStyle();
    const form = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "conditionsGroups",
    });
    const onAddNewConditionGroup = () => {
        const conditionsGroups = {
            outerConditionOperation: conditionOperationOptionsMap[ConditionOperation.And],
            conditions: convertDecisionPlanConditionToFormValues(decisionPlanFields),
        };
        append(conditionsGroups);
    };
    const onRemoveConditionGroup = (index) => {
        remove(index);
    };
    return (_jsxs(_Fragment, { children: [fields === null || fields === void 0 ? void 0 : fields.map((item, index) => {
                return (_jsxs(_Fragment, { children: [(fields === null || fields === void 0 ? void 0 : fields.length) > 1 && index > 0 && (_jsx(Grid, Object.assign({ item: true, lg: 1, alignItems: 'center', display: 'flex', marginBlock: 2 }, { children: _jsx(Container, Object.assign({ className: classes.container }, { children: _jsx(FormToggleButtonGroup, { name: `conditionsGroups.${index}.outerConditionOperation`, options: conditionOperationOptions }) })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(DecisionConditionsForm, { decisionPlanFields: decisionPlanFields, conditionGroupPath: `conditionsGroups.${index}`, onRemoveConditionGroup: () => onRemoveConditionGroup(index) }) }), item === null || item === void 0 ? void 0 : item.id)] }));
            }), _jsx(Grid, Object.assign({ item: true, xs: 12, mt: 2 }, { children: _jsx(Button, Object.assign({ onClick: onAddNewConditionGroup, className: classes.addGroupButton, startIcon: _jsx(CustomIcon, { icon: 'add', color: theme.palette.common.white }) }, { children: t("Add New Group") })) }))] }));
};
