import { CoverageByType, H_EntityError } from "@health/queries/types";
import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, CustomDatePicker, Grid, TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Payers } from "shared/components";
import { InputMember } from "../types";
import moment from "moment";

export const InsuranceInformation: FC<InputMember> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    control,
    watch,
    formState: { errors: reactFormErrors },
  } = useFormContext();

  const formErrors = combineErrors(reactFormErrors, errors);
  const coverageByTypeOptions = Object.keys(CoverageByType)?.map(item => ({
    name: item,
    value: CoverageByType[item],
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <AutocompleteController
          ControllerProps={{
            name: "coverageBy",
            control: control,
            defaultValue: coverageByTypeOptions?.find(el => el.value === defaultValue?.coverageBy) || null,
          }}
          TextFieldProps={{
            error: Boolean(formErrors?.coverageBy?.message),
            helperText: formErrors?.coverageBy?.message,
            label: t("Coverage By"),
          }}
          getOptionLabel={o => t(o?.name)}
          defaultValue={coverageByTypeOptions?.find(el => el.value === defaultValue?.coverageBy) || null}
          options={coverageByTypeOptions || []}
          disabled={!!defaultValue}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomDatePicker
          control={control}
          name='membershipStart'
          defaultValue={defaultValue?.membershipStart}
          label={t("Membership Start Date")}
          placeholder={t("Membership Start Date")}
          error={Boolean(formErrors?.membershipStart?.message)}
          helperText={t(formErrors?.membershipStart?.message)}
          controllerProps={{
            rules: { required: getRequiredValidation(t, true) },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomDatePicker
          control={control}
          name='membershipEnd'
          defaultValue={defaultValue?.membershipEnd}
          label={t("Membership End Date")}
          placeholder={t("Membership End Date")}
          error={Boolean(formErrors?.membershipEnd?.message)}
          helperText={t(formErrors?.membershipEnd?.message)}
          controllerProps={{
            rules: { required: getRequiredValidation(t, true) },
          }}
          datePickerProps={{
            disablePast: true,
            minDate: moment(watch("membershipStart")).add(1, "d"),
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          placeholder={t("Insurance Id")}
          label={t("Insurance Id")}
          defaultValue={defaultValue?.insuranceId}
          error={Boolean(formErrors.insuranceId?.message)}
          helperText={t(formErrors.insuranceId?.message)}
          {...register("insuranceId", { required: getRequiredValidation(t, true) })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          placeholder={t("Insurance Policy Number")}
          label={t("Insurance Policy Number")}
          defaultValue={defaultValue?.insurancePolicyNumber}
          error={Boolean(formErrors.insurancePolicyNumber?.message)}
          helperText={t(formErrors.insurancePolicyNumber?.message)}
          {...register("insurancePolicyNumber", { required: getRequiredValidation(t, true) })}
        />
      </Grid>
      <Grid item xs={4}>
        <Payers<Partial<H_EntityError>> isRequired errors={errors!} payer={defaultValue?.payer!} />
      </Grid>
    </Grid>
  );
};
