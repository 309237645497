import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useDecisionActivationMutation } from "../../../Decisions/gql";
export const DecisionActivation = props => {
    const { id, isActive } = props;
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const [check, setCheck] = useState(isActive);
    const [updateDecisionStatusMutation, { loading }] = useDecisionActivationMutation({
        onCompleted: (data) => {
            var _a;
            const mutationErrors = (_a = data === null || data === void 0 ? void 0 : data.updateDecisionStatus) === null || _a === void 0 ? void 0 : _a.errors;
            if (mutationErrors && (mutationErrors === null || mutationErrors === void 0 ? void 0 : mutationErrors.length) > 0) {
                failed(t(formatMessageErrors(mutationErrors)));
            }
            else {
                succeeded(t("Decision status updated successfully"));
            }
        },
    });
    const handleToggleChange = (event, active) => {
        updateDecisionStatusMutation({
            variables: {
                id,
                isActive: active,
            },
        });
    };
    useEffect(() => {
        setCheck(isActive);
    }, [isActive]);
    return (_jsx(Box, Object.assign({ sx: { display: "flex", alignContent: "flex-start" } }, { children: _jsx(CustomToggleButton, { disabled: loading, value: check, defaultChecked: Boolean(check), onChange: handleToggleChange }) })));
};
