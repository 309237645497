var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, ExcelIcon, useAddToast, useGridContext } from "@toolkit/ui";
import { useAuth } from "react-oidc-context";
import moment from "moment";
import { useExportPaymentInvoicesMutation } from "../gql";
export const ReportDownload = props => {
    const { label } = props;
    const { t } = useTranslation("domains");
    const [isDownloading, setIsDownloading] = useState(false);
    const { failed } = useAddToast();
    const [exportPaymentInvoices, { loading }] = useExportPaymentInvoicesMutation();
    const { user } = useAuth();
    const { activeFiltersProps } = useGridContext();
    const filters = (activeFiltersProps === null || activeFiltersProps === void 0 ? void 0 : activeFiltersProps.filters) || {};
    const handleDownloadFile = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if (isDownloading || loading)
            return;
        exportPaymentInvoices({
            variables: {
                filter: Object.assign(Object.assign({}, filters), { createdDate: {
                        gte: ((_a = filters === null || filters === void 0 ? void 0 : filters.createdDate) === null || _a === void 0 ? void 0 : _a.gte) || moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD"),
                        lte: ((_b = filters === null || filters === void 0 ? void 0 : filters.createdDate) === null || _b === void 0 ? void 0 : _b.lte) || moment().endOf("month").format("YYYY-MM-DD"),
                    }, marketplaceOrderIdNotNull: ((_c = filters === null || filters === void 0 ? void 0 : filters.marketplaceOrderIdNotNull) === null || _c === void 0 ? void 0 : _c.value) === "Yes" }),
            },
        }).then(response => {
            var _a, _b;
            if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.exportPaymentInvoices) {
                setIsDownloading(true);
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${user === null || user === void 0 ? void 0 : user.access_token}`);
                fetch((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.exportPaymentInvoices, {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                })
                    .then(response => response.blob())
                    .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    link.setAttribute("download", `order-invoices.csv`);
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(url);
                    setIsDownloading(false);
                })
                    .catch(error => {
                    console.error("Error fetching template:", error);
                    failed(t("Failed to download report"));
                    setIsDownloading(false);
                });
            }
        });
    });
    return (_jsx(Button, Object.assign({ sx: { margin: "10px", paddingInline: 6, borderRadius: "10px", whiteSpace: "nowrap" }, startIcon: _jsx(ExcelIcon, {}), onClick: handleDownloadFile }, { children: label || t("Download Report") })));
};
