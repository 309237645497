import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { BarChartIcon } from "@toolkit/ui";
import { settingsPaths, settingsRoute } from "pages/Settings/constants/settings-paths";
import { SettingsPage } from "pages/Settings/SettingsPage.screen";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const settingsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "settings-routes",
    text: t("Settings", { ns: "admin" }),
    route: settingsRoute,
    fullPath: settingsPaths.mainPath.fullPath,
    element: <SettingsPage />,
    icon: <BarChartIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageSettings),
    onClick: (route: string) => navigate(route),
  };
};
