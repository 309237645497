import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { getMarketplaceOrderStatusTypeFilter, getMarketplaceOrderTypeFilter } from "@health/enum-options";
import { MarketplaceOrderSortingField, MarketplaceOrderType, OrderDirection } from "@health/queries/types";
import { i18n, useTranslation } from "@toolkit/i18n";
import { OrderStatusTypography, TruncateTypography } from "@toolkit/ui";
import moment from "moment-timezone";
import "moment/locale/ar";
import { getOrderStatusColor } from "../../../OrdersTracking/components/utils";
import { useMemo } from "react";
import { ProductsModal } from "../components/ProductsModal";
import { getVendorsAutocompleteFilter } from "@health/autocompletes";
export const useMarketplaceOrdersTrackingColumns = ({ isAdmin }) => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "number",
                header: t("Order Number"),
                accessor: "id",
                filter: {
                    type: "string",
                    name: "ids",
                },
            },
            {
                key: "vendor",
                header: t("Vendor"),
                accessor: ({ vendor }) => _jsx(TruncateTypography, { text: (vendor === null || vendor === void 0 ? void 0 : vendor.name) || "-" }),
                hideFromPreferencesAndTable: !isAdmin,
                filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
            },
            {
                key: "patient-id",
                header: t("Customer ID"),
                accessor: ({ user }) => user === null || user === void 0 ? void 0 : user.nationalId,
            },
            {
                key: "customer-name",
                header: t("Customer Name"),
                accessor: ({ user }) => user === null || user === void 0 ? void 0 : user.fullName,
            },
            {
                key: "contactEmail",
                header: t("Customer Email"),
                accessor: ({ user }) => { var _a; return _jsx("span", Object.assign({ className: 'mobile-phone-rtl-fix' }, { children: String((_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : "") })); },
            },
            {
                key: "contactNumber",
                header: t("Customer Mobile"),
                accessor: ({ user }) => { var _a; return _jsx("span", Object.assign({ className: 'mobile-phone-rtl-fix' }, { children: String((_a = user === null || user === void 0 ? void 0 : user.mobile) !== null && _a !== void 0 ? _a : "") })); },
            },
            {
                header: t("Payment Price"),
                key: "totalAmount",
                accessor: "totalNetAmount",
            },
            {
                key: "Order Status",
                header: t("Order Status"),
                accessor: ({ status }) => _jsx(OrderStatusTypography, { status: status, color: getOrderStatusColor(status) }),
                filter: getMarketplaceOrderStatusTypeFilter({ name: "statuses", multiple: true }),
            },
            {
                key: "type",
                header: t("Order Type"),
                accessor: ({ type }) => (type === MarketplaceOrderType.HealthPackage ? t("Health Package") : t("Product")),
                filter: getMarketplaceOrderTypeFilter({ name: "type" }),
            },
            {
                key: "orderItems",
                header: t("Order Items"),
                accessor: ({ id }) => _jsx(ProductsModal, { orderId: id, title: t("Items") }),
            },
            {
                key: "Created",
                header: t("Created"),
                accessor: ({ createdDate }) => {
                    moment.localeData("ar");
                    const createdTz = moment.tz.guess();
                    moment.locale(i18n.language);
                    return moment(createdDate).tz(createdTz).format("DD MMM YYYY hh:mm a");
                },
                isSortable: true,
                sortDirection: OrderDirection === null || OrderDirection === void 0 ? void 0 : OrderDirection.Asc,
                sortColumnEnum: MarketplaceOrderSortingField.Created,
            },
            {
                key: "toDate",
                header: t("To Date"),
                accessor: "toDate",
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "toDate",
                },
            },
            {
                key: "fromDate",
                header: t("From Date"),
                accessor: "fromDate",
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "fromDate",
                },
            },
        ];
    }, []);
};
