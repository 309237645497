/* eslint-disable max-lines */
import { hasAnyPermission, hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceProductsPaths, marketplaceProductsRoute } from "./constants/MarketPlaceProductsPaths";
import { ProductsList } from "./List/ProductsListPage";
import { BrandsRoutes } from "pages/MarketplaceBrands";
import { CategoriesRoutes } from "pages/MarketplaceProductCategories";

export const productRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({ navigate, enabled }) => {
  const canManageProductCatalogue =
    enabled && hasAnyPermission([PermissionEnum.ManageProducts, PermissionEnum.ManageBrands, PermissionEnum.ManageProductCategories]);
  return {
    id: "product-catalogue",
    text: i18n.t("Product Catalogue", { ns: "admin" }),
    route: marketplaceProductsRoute,
    isProhibited: !canManageProductCatalogue,
    hidden: !canManageProductCatalogue,
    subItems: [
      {
        id: "products",
        text: i18n.t("Products"),
        route: marketplaceProductsPaths.main.route,
        fullPath: marketplaceProductsPaths.main.fullPath,
        element: <ProductsList />,
        hidden: !hasPermission(PermissionEnum.ManageProducts),
        isProhibited: !hasPermission(PermissionEnum.ManageProducts),
        onClick: route => navigate(route),
      },
      BrandsRoutes({ navigate, enabled }),
      CategoriesRoutes({ navigate, enabled }),
    ],
  };
};
