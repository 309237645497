import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ProcessingStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { ApproveIcon, CustomIcon, MenuItem, Typography } from "@toolkit/ui";
import { useReviewCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const ApproveRejectButtons = ({ onClick }) => {
    const { versionParamId, refetchCodeSystem } = useAdminManagedListDetailsPageContext();
    const [reviewCodeSystem, { loading }] = useReviewCodeSystemMutation();
    const { t } = useTranslation("domains");
    const handleSubmit = (status) => {
        onClick();
        reviewCodeSystem({
            variables: {
                codeSystemId: versionParamId,
                processingStatus: status,
            },
        }).then(() => {
            // To Do Handle with cache
            refetchCodeSystem === null || refetchCodeSystem === void 0 ? void 0 : refetchCodeSystem();
        });
    };
    const handleReject = () => {
        handleSubmit(ProcessingStatus.Rejected);
    };
    const handleApprove = () => {
        handleSubmit(ProcessingStatus.Approved);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(MenuItem, Object.assign({ disabled: loading, onClick: handleReject }, { children: [_jsx(CustomIcon, { icon: 'x', viewBox: '0 0 20 20' }), _jsxs(Typography, Object.assign({ marginLeft: '5px' }, { children: [" ", t("Reject")] }))] })), _jsxs(MenuItem, Object.assign({ disabled: loading, onClick: handleApprove }, { children: [_jsx(ApproveIcon, { color: 'inherit' }), _jsxs(Typography, Object.assign({ marginLeft: '5px' }, { children: [" ", t("Approve")] }))] }))] }));
};
