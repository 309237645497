import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { FormLabel, FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { medicalFormClassificationUpsertFormDefaultValues, medicalFormClassificationUpsertFormSchema, } from "./MedicalFormClassificationUpsertFormSchema";
import { useMedicalFormClassificationUpsertStyle } from "./useMedicalFormClassificationUpsertStyle";
const MedicalFormClassificationUpsertFormForwardRef = (props, ref) => {
    const { classification, onChange } = props;
    const { t } = useTranslation("domains");
    const { classes } = useMedicalFormClassificationUpsertStyle();
    const form = useCustomForm({
        defaultValues: medicalFormClassificationUpsertFormDefaultValues,
        schema: medicalFormClassificationUpsertFormSchema,
    });
    const { setValue, handleSubmit } = form;
    const onSubmit = (values) => {
        if (classification) {
            onChange({
                type: "UPDATE",
                payload: { values },
            });
        }
        else {
            onChange({
                type: "CREATE",
                payload: { values },
            });
        }
    };
    useImperativeHandle(ref, () => ({
        getForm: () => form,
        submit: handleSubmit(onSubmit),
    }));
    useEffect(() => {
        if (classification) {
            setValue("name", classification.name);
            setValue("rangeFrom", classification.rangeFrom);
            setValue("rangeTo", classification.rangeTo);
        }
    }, [classification]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormLabel, Object.assign({ className: classes.titleScore }, { children: [" ", t("Score Values Range"), " "] })) })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(FormNumberField, { name: "rangeFrom", label: t("From") }) })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(FormNumberField, { name: "rangeTo", label: t("To") }) }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormTextField, { name: "name", label: t("Classification Label") }) }))] })) })) })));
};
export const MedicalFormClassificationUpsertForm = forwardRef(MedicalFormClassificationUpsertFormForwardRef);
