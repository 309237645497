import { memo } from "react";
import React from "react";
import { Button, Typography, styled } from "@toolkit/ui";
const Root = styled("div")(() => ({
    minHeight: "100%",
    padding: "0 0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
}));
const SadaError = ({ title, subtitle, onClick }) => {
    return (React.createElement(Root, null,
        React.createElement("h2", null, title),
        React.createElement(Typography, null, subtitle),
        React.createElement(Button, { onClick: onClick }, "Retry")));
};
export default memo(SadaError);
