import { useTranslation } from "@toolkit/i18n";
import React, { useMemo, useState } from "react";
import { Badge, Box, Button, Tooltip, Typography, useTheme } from "../../base/mui";
import { MuiMoreVertIcon } from "../../base/mui-icons";
import { useModal } from "../../hooks";
import { CustomDialog } from "../Dialogs";
import { getColumnsForBackend } from "../GridProvider/utils";
import { TablePreferencesContext } from "./TablePreferencesContext";
import { StyledIconButton } from "./TablePreferencesDialog.style";
import { TablePreferencesDialogProps } from "./TablePreferencesDialog.types";
import { SortableList } from "./components/SortableList/SortableList.components";

// eslint-disable-next-line @typescript-eslint/ban-types
export const TablePreferencesDialog = <T extends object = {}>({
  savedPreferences,
  initialColumnPreferences,
  onApplyPreferences: onSubmit,
  isLoading,
}: TablePreferencesDialogProps<T>): React.ReactElement => {
  const filteredSavedPreferences = useMemo(() => savedPreferences?.filter(item => !item?.hideFromPreferencesAndTable), [savedPreferences]);
  const filteredInitialColumnPreferences = useMemo(
    () => initialColumnPreferences?.filter(item => !item?.hideFromPreferencesAndTable),
    [initialColumnPreferences]
  );
  const theme = useTheme();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const { handleClose: _handleClose, handleOpen: _handleOpen, open: isOpen } = useModal();
  const {
    handleClose: handleCloseConfirmResetDialog,
    handleOpen: handleOpenConfirmResetDialog,
    open: isConfirmResetDialogOpen,
  } = useModal();
  const [columns, setColumns] = useState(filteredSavedPreferences || filteredInitialColumnPreferences);
  const value = useMemo(() => ({ columns, setColumns }), [columns]);
  const handleOpen = () => {
    setColumns(filteredSavedPreferences || filteredInitialColumnPreferences);
    _handleOpen();
  };
  const handleClose = () => {
    setError("");
    _handleClose();
  };
  const handleResetPreferencesTable = () => {
    const preferenceTable = initialColumnPreferences?.map(item => ({ ...item, isHidden: false }));
    const displayedColumns = preferenceTable?.filter(item => !item?.hideFromPreferencesAndTable);
    setColumns(displayedColumns);
    onSubmit(
      preferenceTable?.map(item => {
        return { key: item.key, isHidden: item?.isHidden };
      })
    );
    handleCloseConfirmResetDialog();
  };

  const handleApplyChanges = () => {
    const isValid = columns?.find(item => !item?.isHidden);
    if (isValid) {
      setError("");
      onSubmit?.(getColumnsForBackend(columns, initialColumnPreferences));
      handleClose();
    } else {
      setError(t("You should at least select one column to display"));
    }
  };

  const isAnyOfSystemColumnsChanged = initialColumnPreferences?.length !== savedPreferences?.length;
  const isThereSavedViewSettings = savedPreferences?.length > 0;
  const anyOfSystemColumnsChangedUpdateMessage = t("Please reset your view to see the latest updates in the system columns.");
  const theresNoViewSettings = t("Save your current view settings for future use.");
  const isThereWarning = (isAnyOfSystemColumnsChanged || !isThereSavedViewSettings) && !isLoading;
  const warningMessage = isThereSavedViewSettings ? anyOfSystemColumnsChangedUpdateMessage : theresNoViewSettings;

  return (
    <TablePreferencesContext.Provider value={value}>
      <StyledIconButton color='primary' size='medium' onClick={handleOpen} sx={{ position: "relative" }}>
        <MuiMoreVertIcon />
        {isThereWarning && (
          <Tooltip title={warningMessage}>
            <Badge
              variant='dot'
              color='error'
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                "& .MuiBadge-badge": {
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                },
              }}
            />
          </Tooltip>
        )}
      </StyledIconButton>
      <CustomDialog
        type='base'
        maxWidth={"sm"}
        open={isOpen}
        onClose={handleClose}
        DialogTitleProps={{ title: t("Columns"), onClose: handleClose }}
        DialogContentProps={{
          sx: { padding: "20px" },
        }}
        DialogActionsProps={{
          children: (
            <>
              <Button onClick={handleApplyChanges}>{t("Save")}</Button>
              <Box>
                <Button variant='text' onClick={handleClose}>
                  {t("Cancel")}
                </Button>
              </Box>
              <Box flex={1} />
              <Button disabled={!savedPreferences} onClick={handleOpenConfirmResetDialog}>
                {t("Reset")}
              </Button>
            </>
          ),
        }}
      >
        {isThereWarning && (
          <Box py={2}>
            <Typography color={theme.palette.error.main}>{warningMessage}</Typography>
          </Box>
        )}
        {columns && <SortableList />}
        <Typography color={theme.palette.error.main}>{error}</Typography>
      </CustomDialog>
      {isConfirmResetDialogOpen && (
        <CustomDialog
          type='warning'
          text={{
            body: t("Are you sure you want to reset table preferences?"),
          }}
          isOpen={isConfirmResetDialogOpen}
          onClose={handleCloseConfirmResetDialog}
          onConfirm={handleResetPreferencesTable}
        />
      )}
    </TablePreferencesContext.Provider>
  );
};
