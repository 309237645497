import { adminManagedListsRoutes } from "pages/AdminManagedLists/route";
import { callbackRequestsRoutes } from "pages/CallbackRequests/route";
import { citiesManagementRoutes } from "pages/CitiesManagement/route";
import { dashboardRoutes } from "pages/Dashboard/route";
import { decisionsRoutes } from "pages/Decisions/constants";
import { healthInsuranceNetworkRoutes, healthInsurancesManagementRoutes } from "pages/HealthInsurance/route";
import { healthProgramsRoutes } from "pages/HealthProgram/route";
import { insuranceApprovalsRoutes } from "pages/InsuranceApprovals/route";
import { marketplaceRoutes } from "pages/Marketplace/constants/marketplace-routes.constants";
import { membersRoutes } from "pages/Members/route";
import { PayersRoutes } from "pages/Payers";
import { permissionsManagementRoutes } from "pages/PermissionsManagement/route";
import { prescriptionsOrdersTrackingRoutes } from "pages/PrescriptionsOrdersTracking";
import { providersManagementRoutes } from "pages/ProvidersManagement/route";
import { settingsRoutes } from "pages/Settings/route";
import { streamingRoutes } from "pages/Streaming/route";
import { surveysRoutes } from "pages/Surveys/route";
import { reviewPageUploadRoutes } from "pages/UploadPages/route";
import { ordersRoutes } from "./orders";
import { ordersAdminRoutes } from "./ordersMangment";
import { productsManagementRoutes } from "./products";
import { specializationManagementRoutes } from "./specialization";

export const ROUTE_PATHS = {
  dashboardRoutes,
  adminManagedListsRoutes,
  permissionsManagementRoutes,
  healthInsurancesManagementRoutes,
  healthInsuranceNetworkRoutes,
  healthProgramsRoutes,
  reviewPageUploadRoutes,
  settingsRoutes,
  streamingRoutes,
  productsManagement: productsManagementRoutes,
  providersManagementRoutes,
  membersRoutes,
  citiesManagementRoutes,
  decisionsRoutes,
  surveysRoutes,
  callbackRequestsRoutes,
  insuranceApprovalsRoutes,
  specializationManagementRoutes,
  ordersRoutes,
  ordersAdminRoutes,
  marketplaceRoutes,
  PayersRoutes,
  prescriptionsOrdersTrackingRoutes,
};
