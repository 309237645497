import { hasAnyPermission } from "@health/common";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceOrdersInvoicesPaths, marketplaceInvoicesOrdersRoute } from "./constants/MarketPlaceInvoicesPaths";
import { AdminMarketPlaceOrdersInvoicesContainer } from "./List/MarketPlaceOrdersInvoicesListContainer";
import { PermissionEnum } from "@health/queries/types";

export const marketplaceOrdersInvoicesRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }): RouteItem => {
  return {
    id: "marketplace-order-invoices-routes",
    text: i18n.t("Marketplace Orders Invoices", { ns: "admin" }),
    route: marketplaceInvoicesOrdersRoute,
    fullPath: marketplaceOrdersInvoicesPaths.mainPath.fullPath,
    element: <AdminMarketPlaceOrdersInvoicesContainer />,
    hidden: !hasAnyPermission([PermissionEnum.ManageInvoices]),
    isProhibited: !hasAnyPermission([PermissionEnum.ManageInvoices]),

    onClick: (route: string) => navigate(route),
    subItems: [
      {
        id: "marketplace-order-invoices-routes-id",
        route: marketplaceInvoicesOrdersRoute,
        fullPath: marketplaceOrdersInvoicesPaths.mainPath.fullPath,
        text: i18n.t("Marketplace Orders Invoices", { ns: "admin" }),
        element: <AdminMarketPlaceOrdersInvoicesContainer />,
        onClick: route => navigate(route),
        isProhibited: !hasAnyPermission([PermissionEnum.ManageInvoices]),
        hidden: !hasAnyPermission([PermissionEnum.ManageInvoices]),
      },
    ],
  };
};
