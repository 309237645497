import { ApolloClient, MutationTuple, OperationVariables } from "@apollo/client";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useToasts } from "react-toast-notifications";
import { GMutation } from "../types";
type ExtraVariables = {
  [key: string]: any;
};

export const useDeleteTableRow = <Doc extends Record<string, any>, X extends OperationVariables>(deleteItemProps?: {
  useDeleteMutation: GMutation<Doc, X>;
  name?: string;
  entityTypeName: string;
  idPropName?: string;
  extraVariables?: ExtraVariables;
}) => {
  const {
    name = "",
    entityTypeName,
    useDeleteMutation,
    idPropName = "id",
    extraVariables,
  } = deleteItemProps || {
    useDeleteMutation: () => {
      console.log("useDeleteMutation not implemented");
    },
  };

  const { t } = useTranslation();
  const { addToast } = useToasts();
  const nameCapitalized = name?.charAt(0).toUpperCase() + name?.slice(1) || "";

  const [deleteItem] = useDeleteMutation({
    onCompleted: data => {
      const [deleteProperty] = Object.keys(data);
      const errorObj = data[deleteProperty];
      const errors = Object.keys(errorObj)?.find(item => item.includes("Errors"));
      if (!errors) {
        addToast(t(`${nameCapitalized} Deleted Successfully`), {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        const errorsMutation = errorObj[errors];
        addToast(t(formatMessageErrors(errorsMutation)), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    },
    onError: () => {
      addToast(t(`${nameCapitalized} Delete Failed`), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  }) as MutationTuple<Doc, X>;

  const handleDeleteItem = <T extends { id: unknown }>(row: T) => {
    deleteItem({
      variables: {
        [idPropName || "id"]: row?.id!,
        ...extraVariables,
      } as X,
      update(cache: ApolloClient<object>["cache"]) {
        const normalizedId = cache.identify({ id: row?.id!, __typename: entityTypeName });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  return {
    handleDeleteItem,
  };
};
