import { getEnvVariable } from "@health/env";
import { initializeCoreConfig } from "@toolkit/core";
import { initializeI18nConfig } from "@toolkit/i18n";
import { initializeMapConfig } from "@toolkit/maps";
export default function setupPackages() {
    initializeI18nConfig({
        enableTolgeeWizard: getEnvVariable("ENABLE_TOLGEE_WIZARD", process.env.ENABLE_TOLGEE_WIZARD),
        tolgeeProjectID: getEnvVariable("TOLGEE_PROJECT_ID", process.env.TOLGEE_PROJECT_ID),
        apiUrl: getEnvVariable("TOLGEE_API_URL", process.env.TOLGEE_API_URL),
        apiKey: getEnvVariable("TOLGEE_API_KEY", process.env.TOLGEE_API_KEY),
    });
    initializeCoreConfig({
        baseMediaUrl: getEnvVariable("BASE_MEDIA_URL", process.env.REACT_APP_BASE_MEDIA_URL || ""),
        fileServiceApiUrl: getEnvVariable("FILE_SERVICE_API_URL", process.env.REACT_APP_FILE_SERVICE_API_URL || ""),
    });
    initializeMapConfig({
        googleApiKey: getEnvVariable("GOOGLE_API_KEY", process.env.REACT_APP_GOOGLE_API_KEY),
    });
}
