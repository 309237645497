import { OrderDeliveryStatusEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const orderDeliveryStatusOptionsMap = {
    [OrderDeliveryStatusEnum.CarrierAllocated]: {
        key: OrderDeliveryStatusEnum.CarrierAllocated,
        get label() {
            return i18n.t("Carrier Allocated", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.CarrierAllocated,
    },
    [OrderDeliveryStatusEnum.DeliveryTimedOut]: {
        key: OrderDeliveryStatusEnum.DeliveryTimedOut,
        get label() {
            return i18n.t("Delivery Timed Out", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.DeliveryTimedOut,
    },
    [OrderDeliveryStatusEnum.DriverAcceptedOrder]: {
        key: OrderDeliveryStatusEnum.DriverAcceptedOrder,
        get label() {
            return i18n.t("Driver Accepted Order", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.DriverAcceptedOrder,
    },
    [OrderDeliveryStatusEnum.DriverIsAboutToArrive]: {
        key: OrderDeliveryStatusEnum.DriverIsAboutToArrive,
        get label() {
            return i18n.t("Driver Is About To Arrive", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.DriverIsAboutToArrive,
    },
    [OrderDeliveryStatusEnum.DriverPickedUpOrder]: {
        key: OrderDeliveryStatusEnum.DriverPickedUpOrder,
        get label() {
            return i18n.t("Driver Picked Up Order", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.DriverPickedUpOrder,
    },
    [OrderDeliveryStatusEnum.Failed]: {
        key: OrderDeliveryStatusEnum.Failed,
        get label() {
            return i18n.t("Failed", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.Failed,
    },
    [OrderDeliveryStatusEnum.OrderIsCancelled]: {
        key: OrderDeliveryStatusEnum.OrderIsCancelled,
        get label() {
            return i18n.t("Order Is Cancelled", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.OrderIsCancelled,
    },
    [OrderDeliveryStatusEnum.OrderIsDelivered]: {
        key: OrderDeliveryStatusEnum.OrderIsDelivered,
        get label() {
            return i18n.t("Order Is Delivered", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.OrderIsDelivered,
    },
    [OrderDeliveryStatusEnum.OrderIsResumed]: {
        key: OrderDeliveryStatusEnum.OrderIsResumed,
        get label() {
            return i18n.t("Order Is Resumed", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.OrderIsResumed,
    },
    [OrderDeliveryStatusEnum.OrderIsSuspended]: {
        key: OrderDeliveryStatusEnum.OrderIsSuspended,
        get label() {
            return i18n.t("Order Is Suspended", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.OrderIsSuspended,
    },
    [OrderDeliveryStatusEnum.Pending]: {
        key: OrderDeliveryStatusEnum.Pending,
        get label() {
            return i18n.t("Pending", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.Pending,
    },
    [OrderDeliveryStatusEnum.PickedByConsumer]: {
        key: OrderDeliveryStatusEnum.PickedByConsumer,
        get label() {
            return i18n.t("Picked By Consumer", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.PickedByConsumer,
    },
    [OrderDeliveryStatusEnum.Returned]: {
        key: OrderDeliveryStatusEnum.Returned,
        get label() {
            return i18n.t("Returned", { ns: "domains" });
        },
        value: OrderDeliveryStatusEnum.Returned,
    },
};
export const orderDeliveryStatusOptions = Object.values(orderDeliveryStatusOptionsMap);
