import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, Typography } from "@toolkit/ui";
import { useFormContext } from "react-hook-form";
import { useGetSubListsQuery } from "../../../gql";
export const SubListsAutocomplete = ({ isOpen }) => {
    var _a, _b, _c, _d, _e, _f;
    const { control, formState, register } = useFormContext();
    const { t } = useTranslation("domains");
    const { data, loading: isLoading, fetchMore, } = useGetSubListsQuery({
        variables: {
            first: 10,
            after: null,
            before: null,
            last: null,
        },
        skip: !isOpen,
    });
    const subLists = (_b = (_a = data === null || data === void 0 ? void 0 : data.subLists) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(e => e === null || e === void 0 ? void 0 : e.node);
    const hasMoreData = (_d = (_c = data === null || data === void 0 ? void 0 : data.subLists) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.hasNextPage;
    const fetchMoreData = () => {
        var _a, _b, _c, _d;
        if (!((_b = (_a = data === null || data === void 0 ? void 0 : data.subLists) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.hasNextPage)) {
            return;
        }
        fetchMore({
            variables: {
                first: 10,
                after: (_d = (_c = data === null || data === void 0 ? void 0 : data.subLists) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                var _a, _b;
                if (!fetchMoreResult)
                    return prev;
                return Object.assign(Object.assign({}, fetchMoreResult), { subLists: Object.assign(Object.assign({}, fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.subLists), { edges: [...(((_a = prev === null || prev === void 0 ? void 0 : prev.subLists) === null || _a === void 0 ? void 0 : _a.edges) || []), ...(((_b = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.subLists) === null || _b === void 0 ? void 0 : _b.edges) || [])] }) });
            },
        });
    };
    return (_jsxs(Grid, Object.assign({ item: true, md: 6, sx: { display: "flex", alignItems: "center", justifyContent: "space-around" } }, { children: [_jsxs(Typography, Object.assign({ sx: { minWidth: "60px" } }, { children: [" ", t("Sublist")] })), _jsx(AutocompleteController, Object.assign({ ControllerProps: {
                    name: "sublist",
                    control: control,
                }, fetchMoreData: fetchMoreData, options: subLists || [], hasMore: hasMoreData, loading: isLoading, getOptionLabel: o => o === null || o === void 0 ? void 0 : o.display, TextFieldProps: {
                    error: Boolean((_e = formState.errors.sublist) === null || _e === void 0 ? void 0 : _e.message),
                    helperText: (_f = formState.errors.sublist) === null || _f === void 0 ? void 0 : _f.message,
                    label: t("SubList"),
                } }, register("sublist")))] })));
};
