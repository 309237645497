import { getEnvVariable } from "@health/env";
const getDownloadServiceURL = () => {
    return (getEnvVariable("FILE_SERVICE_API_URL", process.env.REACT_APP_FILE_SERVICE_API_URL || process.env.NEXT_PUBLIC_FILE_SERVICE_API_URL) +
        "/download");
};
export const getMediaLink = (file) => {
    if (!file)
        return "";
    if (typeof file !== "string") {
        if (Object.prototype.hasOwnProperty.call(file, "url") && (file === null || file === void 0 ? void 0 : file["url"])) {
            return `${getDownloadServiceURL()}/${file === null || file === void 0 ? void 0 : file["url"]}`;
        }
        return file;
    }
    if (file.startsWith("http"))
        return file;
    if (file.includes(getEnvVariable("BASE_MEDIA_URL", process.env.REACT_APP_BASE_MEDIA_URL || process.env.NEXT_PUBLIC_BASE_MEDIA_URL)))
        return file;
    return `${getDownloadServiceURL()}/${file}`;
};
export const getAssetsFromFileStorage = (name) => getDownloadServiceURL() + "/" + name;
