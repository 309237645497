import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuditHistoryDrawer, AuditHistoryHeader, Box, OrdersEventsContainer } from "@toolkit/ui";
import { useLifeCycleHooks } from "./LifeCycle.hook";
import { OrdersEvents } from "./OrdersEvents.components";
export const LifeCycleModel = ({ prescriptionID, number, referenceNumber, dispenseStatus }) => {
    const { open, loading, ordersData, pageInfo, value, handleChange, handleSearch, fetchMoreData, handleClickOpen, handleClickClose } = useLifeCycleHooks(prescriptionID, number);
    return (_jsx(AuditHistoryDrawer, Object.assign({ open: open, onClickOpen: handleClickOpen, onClickClose: handleClickClose }, { children: _jsxs(Box, Object.assign({ padding: '14px', height: '100vh', overflow: 'auto', id: 'scrollableDiv' }, { children: [_jsx(AuditHistoryHeader, { referenceNumber: referenceNumber, value: value, onChange: handleChange, onSearch: handleSearch, ordersData: ordersData }), _jsx(OrdersEventsContainer, { loading: loading, pageInfo: pageInfo, ordersData: ordersData, fetchMoreData: fetchMoreData, 
                    // dispenseStatus={dispenseStatus} // ToDo : fix this as the component is not accepting this props in typing
                    renderItem: ({ item }) => (_jsx(OrdersEvents, { dispenseStatus: dispenseStatus, events: item === null || item === void 0 ? void 0 : item.events, status: item === null || item === void 0 ? void 0 : item.status, failReason: item === null || item === void 0 ? void 0 : item.failReason, helpDiskTicketId: item === null || item === void 0 ? void 0 : item.helpDiskTicketId })) })] })) })));
};
