import { jsx as _jsx } from "react/jsx-runtime";
import { OrderEventsEnum, OrderStatus } from "@health/queries/types";
import { OrdersEvent, Timeline } from "@toolkit/ui";
import { Fragment } from "react";
export const OrdersEvents = ({ events, helpDiskTicketId, failReason, status }) => {
    const handleVoid = () => { };
    return (_jsx(Timeline, Object.assign({ placeholder: '', sx: { margin: 0, padding: "0px 16px" }, onPointerEnterCapture: handleVoid, onPointerLeaveCapture: handleVoid }, { children: events === null || events === void 0 ? void 0 : events.map((event, index) => {
            const failedType = event.type === OrderEventsEnum.Failed ||
                event.type === OrderEventsEnum.PaymentFailed ||
                event.type === OrderEventsEnum.ErxHubClaimFailed ||
                event.type === OrderEventsEnum.OrderDeliveryFailed ||
                event.type === OrderEventsEnum.ErxHubAuthRequestFailed;
            return (_jsx(Fragment, { children: _jsx(OrdersEvent, { event: event, number: event === null || event === void 0 ? void 0 : event.number, branchName: event === null || event === void 0 ? void 0 : event.branchName, failReason: failReason, helpDiskTicketId: helpDiskTicketId, failedType: failedType && status === OrderStatus.Failed && (event === null || event === void 0 ? void 0 : event.isParentEvent) }) }, String(event === null || event === void 0 ? void 0 : event.id) + index));
        }) })));
};
