import { RequestedBy } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const requestedByOptionsMap = {
    [RequestedBy.Patient]: {
        key: RequestedBy.Patient,
        get label() {
            return i18n.t("Patient", { ns: "domains" });
        },
        value: RequestedBy.Patient,
    },
    [RequestedBy.ProviderGuidedCareHealthProgramTeam]: {
        key: RequestedBy.ProviderGuidedCareHealthProgramTeam,
        get label() {
            return i18n.t("Provider Guided Care Health Program Team", { ns: "domains" });
        },
        value: RequestedBy.ProviderGuidedCareHealthProgramTeam,
    },
};
export const requestedByOptions = Object.values(requestedByOptionsMap);
