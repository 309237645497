import { useTranslation } from "@toolkit/i18n";
import moment from "moment/moment";
import React from "react";
import {
  Box,
  Card,
  Divider,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  Typography,
} from "../../base/mui";
import { CardContent } from "./CardContent";
import { EventProps, OrdersEventsProps } from "./types";
import { useOrderEventStyles } from "./useOrderEventStyles";
import { getTypeEvent } from "./utils";

export const OrdersEvent = <T extends EventProps, S>(props: OrdersEventsProps<T, S>) => {
  const { i18n, t } = useTranslation();
  const { event, helpDiskTicketId, failReason, failedType, branchName, number } = props;

  const { classes, cx } = useOrderEventStyles();

  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.content}>
        <Typography className={classes.dateBold}>{moment(event?.date).locale(i18n.language).format("D MMM YYYY")}</Typography>
      </TimelineOppositeContent>

      <TimelineSeparator sx={{ position: "relative" }}>
        <TimelineDot variant='outlined' className={classes.dot} />
        <TimelineConnector className={classes.separator} />
      </TimelineSeparator>

      <TimelineContent className={classes.position}>
        <Card className={cx(classes.card, event?.isParentEvent && classes.parentCard)}>
          <div className={classes.flex}>
            <Typography className={classes.textBold}>{getTypeEvent(event?.type!)}</Typography>
            <Typography className={classes.date}>{moment(event?.date).locale(i18n.language).format("h:mm:ss a")}</Typography>
          </div>
          <Divider className={classes.line} />

          <Box display='flex' justifyContent='space-between'>
            <CardContent title={t("Order Number")} subTitle={"# " + String(number)} />
            <CardContent title={t("Pharmacy")} subTitle={branchName} />
          </Box>
          {failedType && (
            <Box sx={{ mt: 2 }}>
              <CardContent title={t("Fail Reason")} subTitle={failReason} />
            </Box>
          )}
          {helpDiskTicketId && <CardContent title={t("Help Disk Ticket Id")} subTitle={helpDiskTicketId} />}
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
};
