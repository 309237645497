/* eslint-disable no-nested-ternary */
import type { StylisPlugin } from "@emotion/cache";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { isRTL, useTranslation } from "@toolkit/i18n";
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { getFreshTheme as getFreshDigitalTwinTheme } from "./digital-twin/theme";
import { getFreshTheme as getFreshIoHealthTheme } from "./theme";

export const AppThemeProvider: FC<PropsWithChildren<{ theme?: "digital-twin" | "iohealth" }>> = ({ children, theme = "iohealth" }) => {
  const { i18n } = useTranslation();
  const direction = i18n.dir(i18n.language);
  const isRtl = isRTL();

  const [appTheme, setAppTheme] = useState(() => {
    const themesMapper = {
      "digital-twin": getFreshDigitalTwinTheme(),
      iohealth: getFreshIoHealthTheme(),
    };
    return themesMapper[theme];
  });

  const muiCache = useMemo(() => {
    return createCache({
      key: isRtl ? "mui-rtl" : "mui",
      prepend: true,
      stylisPlugins: isRtl ? ([prefixer, rtlPlugin] as StylisPlugin[]) : ([prefixer] as StylisPlugin[]),
    });
  }, [direction]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.dir = direction;
      document?.getElementsByTagName("html")?.[0]?.setAttribute("dir", direction);
      document?.getElementsByTagName("html")?.[0]?.setAttribute("lang", i18n.language);
      document?.getElementsByTagName("body")?.[0]?.setAttribute("dir", direction);
      setAppTheme({ ...appTheme, direction });
    }
  }, [direction]);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};
