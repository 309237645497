import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { conditionOperationOptions, conditionOperationOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Button, Container, CustomIcon, FormAutocomplete, FormCard, Grid, IconButton } from "@toolkit/ui";
import classNames from "classnames";
import { ConditionOperation } from "@health/queries/types";
import { DecisionConditionsUpsertForm } from "../../../Decisions/forms/DecisionConditionUpsert/DecisionConditionsUpsertForm";
import { convertDecisionPlanConditionToFormValues } from "../../../Decisions/others/decisionConditionUtils";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDecisionConditionsFormStyle } from "./useDecisionConditionsFormStyle";
export const DecisionConditionsForm = props => {
    const { decisionPlanFields, conditionGroupPath, onRemoveConditionGroup } = props;
    const { t } = useTranslation("domains");
    const form = useFormContext();
    const { control, watch, setValue } = form;
    const innerConditionOperation = watch(`${conditionGroupPath}.innerConditionOperation`);
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${conditionGroupPath}.conditions`,
    });
    const isFieldsMoreThanOne = (fields === null || fields === void 0 ? void 0 : fields.length) > 1;
    const { classes, theme } = useDecisionConditionsFormStyle({ isFieldsMoreThanOne });
    const onAddNewCondition = () => {
        const condition = convertDecisionPlanConditionToFormValues(decisionPlanFields);
        append(condition);
    };
    const onRemoveCondition = (index) => {
        remove(index);
    };
    useEffect(() => {
        if (!innerConditionOperation && (fields === null || fields === void 0 ? void 0 : fields.length) > 1) {
            setValue(`${conditionGroupPath}.innerConditionOperation`, conditionOperationOptionsMap[ConditionOperation.And]);
        }
        else if ((fields === null || fields === void 0 ? void 0 : fields.length) == 1) {
            setValue(`${conditionGroupPath}.innerConditionOperation`, undefined);
        }
    }, [fields, innerConditionOperation, conditionGroupPath, setValue]);
    return (_jsxs(FormCard, Object.assign({ loading: false, doYouHaveData: true, cardHeader: _jsx(IconButton, Object.assign({ onClick: onRemoveConditionGroup }, { children: _jsx(CustomIcon, { color: theme.palette.secondary.main, icon: 'delete' }) })) }, { children: [_jsxs("div", Object.assign({ className: classes.div }, { children: [isFieldsMoreThanOne && (_jsx(Grid, Object.assign({ item: true, xs: 1, className: classes.gridItem }, { children: _jsx(Container, Object.assign({ className: classes.container }, { children: _jsx(FormAutocomplete, { name: `${conditionGroupPath}.innerConditionOperation`, options: conditionOperationOptions, disableClearable: true, freeSolo: true, forcePopupIcon: true, sx: {
                                    ".MuiAutocomplete-inputRoot": {
                                        backgroundColor: theme.palette.primary.main,
                                        paddingInline: "10px !important",
                                    },
                                    ".MuiAutocomplete-input": {
                                        color: theme.palette.common.white,
                                        "&:focus": {
                                            backgroundColor: "transparent",
                                        },
                                    },
                                    ".MuiAutocomplete-endAdornment": {
                                        button: {
                                            color: theme.palette.common.white,
                                        },
                                    },
                                } }) })) }))), _jsx(Grid, Object.assign({ container: true }, { children: fields === null || fields === void 0 ? void 0 : fields.map((item, index) => {
                            return (_jsx(Grid, Object.assign({ container: true, spacing: 2, sx: { position: "relative" } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 2, className: classNames(classes.conditionInfoGrid, { [classes.dashedConditionInfoGrid]: index !== 0 }) }, { children: _jsx(DecisionConditionsUpsertForm, { isFieldsMoreThanOne: isFieldsMoreThanOne, decisionPlanFields: decisionPlanFields, conditionPath: `${conditionGroupPath}.conditions.${index}`, onRemoveCondition: () => onRemoveCondition(index) }) })) }), item === null || item === void 0 ? void 0 : item.id));
                        }) }))] })), _jsx(Grid, Object.assign({ container: true, spacing: 2, marginTop: "10px" }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Button, Object.assign({ startIcon: _jsx(CustomIcon, { icon: 'add', color: 'inherit' }), onClick: onAddNewCondition }, { children: t("Add New Condition") })) })) }))] })));
};
