import { TemplateInterventionCategory } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const guidedCareTemplateInterventionCategoryOptionsMap = {
    [TemplateInterventionCategory.Insulin]: {
        key: TemplateInterventionCategory.Insulin,
        get label() {
            return i18n.t("Insulin", { ns: "domains" });
        },
        value: TemplateInterventionCategory.Insulin,
    },
    [TemplateInterventionCategory.Pregnancy]: {
        key: TemplateInterventionCategory.Pregnancy,
        get label() {
            return i18n.t("Pregnancy", { ns: "domains" });
        },
        value: TemplateInterventionCategory.Pregnancy,
    },
    [TemplateInterventionCategory.WeightLoss]: {
        key: TemplateInterventionCategory.WeightLoss,
        get label() {
            return i18n.t("Weight Loss", { ns: "domains" });
        },
        value: TemplateInterventionCategory.WeightLoss,
    },
};
export const guidedCareTemplateInterventionCategoryOptions = Object.values(guidedCareTemplateInterventionCategoryOptionsMap);
