/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { categoriesPaths, categoriesRoute } from "./CategoriesPaths";
import { CategoriesListContainer } from "../containers";
import { CategoryUpdateContainer } from "../containers/CategoryUpdate/CategoryUpdateContainer";
import { CategoryCreateContainer } from "../containers/CategoryCerate/CategoryCreateContainer";
import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@health/common";

export const CategoriesRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({ navigate, enabled }) => {
  return {
    id: "marketplace-product-categories",
    text: i18n.t("Categories", { ns: "admin" }),
    route: categoriesRoute,
    hidden: !enabled || !hasPermission(PermissionEnum.ManageProductCategories),
    isProhibited: !enabled || !hasPermission(PermissionEnum.ManageProductCategories),
    subItems: [
      {
        id: "categories-list",
        text: i18n.t("Categories", { ns: "admin" }),
        route: categoriesPaths.main.route,
        fullPath: categoriesPaths.main.fullPath,
        element: <CategoriesListContainer />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "category-new",
        text: i18n.t("New", { ns: "admin" }),
        element: <CategoryCreateContainer />,
        route: categoriesPaths.new.route,
        fullPath: categoriesPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "category-update",
        text: i18n.t("Update"),
        element: <CategoryUpdateContainer />,
        route: categoriesPaths.update.route,
        fullPath: categoriesPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "subcategories-list",
        text: i18n.t("Subcategories", { ns: "admin" }),
        route: categoriesPaths.productCategoryCategories.route,
        fullPath: categoriesPaths.productCategoryCategories.fullPath,
        element: <CategoriesListContainer hasParent />,
        hidden: true,
        isProhibited: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "subcategories-new",
        text: i18n.t("Subcategories New", { ns: "admin" }),
        route: categoriesPaths.newSubcategory.route,
        fullPath: categoriesPaths.newSubcategory.fullPath,
        element: <CategoryCreateContainer hasParent />,
        hidden: true,
        isProhibited: !enabled,
        onClick: route => navigate(route),
      },
    ],
  };
};
