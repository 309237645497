import React, { useEffect, useState } from "react";
import { Controller, ControllerProps, FieldValues, useFormContext } from "react-hook-form";
import { FormHelperText } from "../../base/mui";
import { MDTextEditor } from "./MDTextEditor";

interface MDEditorFieldProps<T extends FieldValues> {
  controllerProps: Pick<ControllerProps<T>, "name" | "rules" | "control">;
  defaultValue: string;
  placeholder?: string;
  label?: string;
  token?: string;
  direction?: "ltr" | "rtl";
}
export const MDEditorField = <T extends FieldValues>({
  controllerProps,
  defaultValue,
  placeholder,
  label,
  token,
  direction,
}: MDEditorFieldProps<T>): React.ReactElement => {
  const { getFieldState, setValue: setFormHookValue } = useFormContext<T>();
  const { name } = controllerProps || {};
  const [value, setValue] = useState<string>();
  const fieldState = getFieldState(name!);

  const handleChangeEditorValue = (htmlValue?: any) => {
    setValue(htmlValue);
    setFormHookValue(name!, htmlValue!);
  };

  useEffect(() => {
    if (!defaultValue) return;
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Controller
        {...controllerProps}
        render={() => (
          <MDTextEditor
            token={token}
            label={label}
            placeholder={placeholder}
            value={value!}
            onChange={handleChangeEditorValue}
            direction={direction}
          />
        )}
      />

      <FormHelperText error={Boolean(fieldState?.error?.message)}>{fieldState?.error?.message}</FormHelperText>
    </>
  );
};

MDEditorField.defaultProps = {
  placeholder: "",
  label: "",
};
