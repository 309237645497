import { hasPermission } from "@health/common";
import { DecisionPlanCategory, PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { BusinessRulesIcon } from "@toolkit/ui";
import { subListsRoutes } from "pages/Sublists/constants";
import { labRoutes, patientEligibilityRoutes, riskStratificationRoutes, userRequiredActionRoutes } from "./DecisionsPaths";
import { AdminDecisionListContainer, AdminDecisionCreateContainer, AdminDecisionUpdateContainer } from "../containers";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const decisionsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "decision-routes",
    text: t("System Rules", { ns: "admin" }),
    icon: <BusinessRulesIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageRuleEngineRules),
    subItems: [
      {
        id: "patient-eligibility-rules-routes",
        text: t("Patient Eligibility Plans", { ns: "admin" }),
        route: patientEligibilityRoutes.route,
        subItems: [
          {
            id: "patient-eligibility-rules-list-route",
            text: "",
            route: patientEligibilityRoutes.paths.main.route,
            fullPath: patientEligibilityRoutes.paths.main.fullPath,
            element: <AdminDecisionListContainer category={DecisionPlanCategory.PatientEligibility} />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "patient-eligibility-rules-new-route",
            text: "",
            route: patientEligibilityRoutes.paths.new.route,
            fullPath: patientEligibilityRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.PatientEligibility} />,
          },
          {
            id: "patient-eligibility-rules-edit-route",
            text: "",
            route: patientEligibilityRoutes.paths.edit.route,
            fullPath: patientEligibilityRoutes.paths.edit.fullPath,
            hidden: true,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.PatientEligibility} />,
          },
        ],
      },
      {
        id: "risk-stratification-rules-routes",
        text: t("Risk Stratification Rules", { ns: "admin" }),
        route: riskStratificationRoutes.route,
        subItems: [
          {
            id: "risk-stratification-rules-main-route",
            text: "",
            route: riskStratificationRoutes.paths.main.route,
            fullPath: riskStratificationRoutes.paths.main.fullPath,
            element: <AdminDecisionListContainer category={DecisionPlanCategory.RiskStratification} />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "risk-stratification-rules-new-route",
            text: "",
            route: riskStratificationRoutes.paths.new.route,
            fullPath: riskStratificationRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.RiskStratification} />,
          },
          {
            id: "risk-stratification-rules-edit-route",
            text: "",
            route: riskStratificationRoutes.paths.edit.route,
            fullPath: riskStratificationRoutes.paths.edit.fullPath,
            hidden: true,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.RiskStratification} />,
          },
        ],
      },
      {
        id: "health-condition-rules-routes",
        text: t("Health Condition Rules", { ns: "admin" }),
        route: labRoutes.route,
        subItems: [
          {
            id: "lab-rules-list-route",
            text: "",
            route: labRoutes.paths.main.route,
            fullPath: labRoutes.paths.main.fullPath,
            onClick: (route: string) => navigate(route),
            element: <AdminDecisionListContainer category={DecisionPlanCategory.HealthCondition} />,
          },
          {
            id: "lab-rules-new-route",
            text: "",
            route: labRoutes.paths.new.route,
            fullPath: labRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.HealthCondition} />,
          },
          {
            id: "lab-rules-edit-route",
            text: "",
            route: labRoutes.paths.edit.route,
            fullPath: labRoutes.paths.edit.fullPath,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.HealthCondition} />,
            hidden: true,
          },
        ],
      },
      {
        id: "user-required-action-rules-routes",
        text: t("User Required Action Rules", { ns: "admin" }),
        route: userRequiredActionRoutes.route,
        subItems: [
          {
            id: "user-required-action-rules-route",
            text: "",
            route: userRequiredActionRoutes.paths.main.route,
            fullPath: userRequiredActionRoutes.paths.main.fullPath,
            onClick: (route: string) => navigate(route),
            element: <AdminDecisionListContainer category={DecisionPlanCategory.UserRequiredAction} />,
          },
          {
            id: "user-required-action-rules-new-route",
            text: "",
            route: userRequiredActionRoutes.paths.new.route,
            fullPath: userRequiredActionRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.UserRequiredAction} />,
          },
          {
            id: "user-required-action-rules-edit-route",
            text: "",
            route: userRequiredActionRoutes.paths.edit.route,
            fullPath: userRequiredActionRoutes.paths.edit.fullPath,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.UserRequiredAction} />,
            hidden: true,
          },
        ],
      },
      subListsRoutes({ navigate, t }),
    ],
  };
};
