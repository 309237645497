import { jsx as _jsx } from "react/jsx-runtime";
import { CustomDialog, CustomTable, ShowButton, useModal } from "@toolkit/ui";
import { useMemo } from "react";
import { useGetOrderLinesQuery } from "../../gql";
import { useProductsColumns } from "./useGetProductsTableColumns";
import { useTranslation } from "@toolkit/i18n";
export const ProductsModal = ({ title, orderId }) => {
    const { t } = useTranslation("domains");
    const { open: isOpen, handleClose, handleOpen } = useModal();
    const { data, loading } = useGetOrderLinesQuery({
        variables: {
            orderId,
        },
        skip: !isOpen,
    });
    const orderItemsLines = useMemo(() => {
        var _a;
        return (_a = data === null || data === void 0 ? void 0 : data.marketplaceOrder) === null || _a === void 0 ? void 0 : _a.orderLines.map(line => {
            const item = (line === null || line === void 0 ? void 0 : line.healthPackage) || (line === null || line === void 0 ? void 0 : line.product);
            return Object.assign(Object.assign({}, line), { type: (line === null || line === void 0 ? void 0 : line.healthPackage) ? t("Health Package") : t("Product"), marketPlaceItem: {
                    id: item === null || item === void 0 ? void 0 : item.id,
                    name: item === null || item === void 0 ? void 0 : item.name,
                    nameAr: item === null || item === void 0 ? void 0 : item.nameAr,
                    mainImageUrl: item === null || item === void 0 ? void 0 : item.mainImageUrl,
                    shortDescription: item === null || item === void 0 ? void 0 : item.shortDescription,
                    shortDescriptionAr: item === null || item === void 0 ? void 0 : item.shortDescriptionAr,
                } });
        });
    }, [data]);
    return (_jsx(CustomDialog, Object.assign({ type: 'base', open: isOpen, onBackdropClick: handleClose, DialogTitleProps: {
            title: title,
            onClose: handleClose,
        }, title: title, button: _jsx(ShowButton, { disabled: loading, onClick: handleOpen }) }, { children: _jsx(CustomTable, { data: orderItemsLines || [], columns: useProductsColumns(), TableContainerProps: {
                sx: {
                    height: 350,
                },
            }, hasFooter: false }) })));
};
