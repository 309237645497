import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { AppTypes } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { UsersGroupIcon } from "@toolkit/ui";
import {
  adminPermissionsManagementPaths,
  adminPermissionsManagementRoute,
} from "pages/PermissionsManagement/constants/admin-permissions-management-paths";
import { permissionsManagementPaths } from "pages/PermissionsManagement/constants/permissions-management-paths";
import {
  vendorPermissionsManagementPaths,
  vendorPermissionsManagementRoute,
} from "pages/PermissionsManagement/constants/vendor-permissions-management-paths";
import { PermissionGroupEditPage, PermissionGroupNewPage, PermissionsList } from "pages/PermissionsManagement/Permissions";
import { AdminUserManagementEditFormPage } from "pages/UserManagement/AdminUsers/Edit";
import AdminUsersManagementList from "pages/UserManagement/AdminUsers/List/AdminUserManagementList.screen";
import { AdminUserManagementNewFormPage } from "pages/UserManagement/AdminUsers/New";
import { adminUserManagementPaths, adminUserManagementRoute } from "pages/UserManagement/constants/admin-user-management-paths";
import { providerUserManagementPaths, providerUserManagementRoute } from "pages/UserManagement/constants/provider-user-management-paths";
import { HealthProviderUserManagementEditFormPage } from "pages/UserManagement/VendorUsers/Edit";
import HealthProviderManagementList from "pages/UserManagement/VendorUsers/List/HealthProviderManagmentList.screen";
import { HealthProviderUserManagementNewFormPage } from "pages/UserManagement/VendorUsers/New";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const permissionsManagementRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  const canManageUser = hasPermission(PermissionEnum.ManageUsers);
  const canManagePermissionGroup = hasPermission(PermissionEnum.ManagePermissionGroup);
  const canViewUsers = hasPermission(PermissionEnum.ViewUsers);
  const canViewOrManageUsers = canViewUsers || canManageUser;

  return {
    id: "permissions-management-routes",
    text: t("Users Management", { ns: "admin" }),
    icon: <UsersGroupIcon />,
    isProhibited: !canViewOrManageUsers && !canManagePermissionGroup,
    subItems: [
      {
        id: "admin-user-management-routes",
        text: t("Admins Users Management", { ns: "admin" }),
        route: adminUserManagementRoute,
        subItems: [
          {
            id: "admin-user-management-list-route",
            text: t("Admins Users Management", { ns: "admin" }),
            route: adminUserManagementPaths.listPath.route,
            fullPath: adminUserManagementPaths.listPath.fullPath,
            element: <AdminUsersManagementList />,
            isProhibited: !canViewOrManageUsers,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "admin-user-management-new-route",
            text: t("New Admin User", { ns: "admin" }),
            route: adminUserManagementPaths.newPath.route,
            fullPath: adminUserManagementPaths.newPath.fullPath,
            hidden: true,
            element: <AdminUserManagementNewFormPage />,
            isProhibited: !canManageUser,
          },
          {
            id: "admin-user-management-edit-route",
            text: t("Edit Admin User", { ns: "admin" }),
            route: adminUserManagementPaths.editPath.route,
            fullPath: adminUserManagementPaths.editPath.fullPath,
            hidden: true,
            element: <AdminUserManagementEditFormPage />,
            isProhibited: !canManageUser,
          },
        ],
      },
      {
        id: "health-provider-user-management-routes",
        text: t("Health Providers Users Management", { ns: "admin" }),
        route: providerUserManagementRoute,
        subItems: [
          {
            id: "health-provider-user-management-list-route",
            text: t("Health Providers Users Management", { ns: "admin" }),
            route: providerUserManagementPaths.listPath.route,
            fullPath: providerUserManagementPaths.listPath.fullPath,
            element: <HealthProviderManagementList />,
            isProhibited: !canViewOrManageUsers,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-provider-user-management-edit-route",
            text: t("Edit Provider User", { ns: "admin" }),
            route: providerUserManagementPaths.editPath.route,
            fullPath: providerUserManagementPaths.editPath.fullPath,
            hidden: true,
            isProhibited: !canManageUser,
            element: <HealthProviderUserManagementEditFormPage />,
          },
          {
            id: "health-provider-user-management-new-route",
            text: t("New Provider User", { ns: "admin" }),
            route: providerUserManagementPaths.newPath.route,
            fullPath: providerUserManagementPaths.newPath.fullPath,
            hidden: true,
            isProhibited: !canManageUser,
            element: <HealthProviderUserManagementNewFormPage />,
          },
        ],
      },
      {
        id: "admin-permissions-management-list-route",
        text: t("Permission Management", { ns: "admin" }),
        route: adminPermissionsManagementRoute,
        fullPath: adminPermissionsManagementPaths.listPath.fullPath,
        element: <PermissionsList appType={AppTypes.Admin} />,
        isProhibited: !canManageUser && !canManagePermissionGroup,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "vendor-permissions-management-list-route",
        text: t("Health Provider Permission Management", { ns: "admin" }),
        route: vendorPermissionsManagementRoute,
        fullPath: vendorPermissionsManagementPaths.listPath.fullPath,
        element: <PermissionsList appType={AppTypes.Vendor} />,
        hidden: true,
        isProhibited: true,
      },
      {
        id: "permissions-management-edit-route",
        text: t("Permission Group Edit", { ns: "admin" }),
        route: permissionsManagementPaths.editPath.fullPath,
        fullPath: permissionsManagementPaths.editPath.fullPath,
        hidden: true,
        isProhibited: !canManagePermissionGroup,
        element: <PermissionGroupEditPage appType={AppTypes.Admin} />,
      },
      {
        id: "permissions-management-new-route",
        text: t("Permission Group New", { ns: "admin" }),
        route: permissionsManagementPaths.newPath.fullPath,
        fullPath: permissionsManagementPaths.newPath.fullPath,
        hidden: true,
        isProhibited: !canManagePermissionGroup,
        element: <PermissionGroupNewPage appType={AppTypes.Admin} />,
      },
    ],
  };
};
