import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
const defaultOptions = {};
export const GetFeatureFlagDocument = gql `
  query getFeatureFlag {
    featureFlag {
      healthPackageFeatureEnabled
      marketplaceFeatureEnabled
    }
  }
`;
/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureFlagQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetFeatureFlagDocument, options);
}
export function useGetFeatureFlagLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetFeatureFlagDocument, options);
}
