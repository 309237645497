/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMembersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramMemberFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramMemberSortingInput>;
}>;


export type HealthProgramMembersQuery = { __typename?: 'Query', healthProgramMembers?: { __typename?: 'HealthProgramMemberCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberCountableEdge', node: { __typename?: 'HealthProgramMember', id: string, firstName?: string | null, lastName?: string | null, phone?: string | null, coverageBy?: Types.CoverageByType | null, membershipEnd?: any | null, membershipStart?: any | null, insuranceId?: string | null, insurancePolicyNumber?: string | null, nationalId?: string | null, nationality?: string | null, nationalityDisplay?: string | null, nationalityArabicDisplay?: string | null, idType?: Types.H_IdTypes | null, documentExpiryDate?: any | null, email?: string | null, gender?: Types.H_Gender | null, dateOfBirth?: any | null, parentNationalId?: string | null, relationType?: Types.H_RelationTypes | null, created?: any | null, sehacityId?: string | null, payer?: { __typename?: 'Payer', id: string, name: string } | null } }> } | null };


export const HealthProgramMembersDocument = gql`
    query healthProgramMembers($first: Int, $after: String, $last: Int, $before: String, $filter: HealthProgramMemberFilterInput, $sortBy: HealthProgramMemberSortingInput) {
  healthProgramMembers(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        firstName
        lastName
        phone
        coverageBy
        membershipEnd
        membershipStart
        insuranceId
        insurancePolicyNumber
        nationalId
        nationality
        nationalityDisplay
        nationalityArabicDisplay
        idType
        documentExpiryDate
        email
        gender
        dateOfBirth
        payer {
          id
          name
        }
        parentNationalId
        relationType
        created
        sehacityId
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMembersQuery__
 *
 * To run a query within a React component, call `useHealthProgramMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMembersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramMembersQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMembersQuery, HealthProgramMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMembersQuery, HealthProgramMembersQueryVariables>(HealthProgramMembersDocument, options);
      }
export function useHealthProgramMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMembersQuery, HealthProgramMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMembersQuery, HealthProgramMembersQueryVariables>(HealthProgramMembersDocument, options);
        }
export type HealthProgramMembersQueryHookResult = ReturnType<typeof useHealthProgramMembersQuery>;
export type HealthProgramMembersLazyQueryHookResult = ReturnType<typeof useHealthProgramMembersLazyQuery>;
export type HealthProgramMembersQueryResult = Apollo.QueryResult<HealthProgramMembersQuery, HealthProgramMembersQueryVariables>;