import { getUser as getUserFromLocalStorage } from "./utils";
export const getUserPermissions = () => {
    const userData = getUserFromLocalStorage();
    return (userData === null || userData === void 0 ? void 0 : userData.permissions) || [];
};
export const isSuperAdmin = () => {
    var _a, _b;
    const userData = getUserFromLocalStorage();
    return ((_a = userData === null || userData === void 0 ? void 0 : userData.app_role) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "ADMIN".toLowerCase() && ((_b = userData === null || userData === void 0 ? void 0 : userData.app_type) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === "ADMIN".toLowerCase();
};
export const hasPermission = (permission) => {
    var _a;
    if (isSuperAdmin())
        return true;
    const permissionCode = Object.prototype.hasOwnProperty.call(permission, "code")
        ? permission.code
        : permission || null;
    if (!permissionCode)
        return false;
    return (_a = getUserPermissions()) === null || _a === void 0 ? void 0 : _a.some(p => (p === null || p === void 0 ? void 0 : p.toLowerCase()) === (permissionCode === null || permissionCode === void 0 ? void 0 : permissionCode.toLowerCase()));
};
export const hasAnyPermission = (permissions) => {
    return permissions.some(hasPermission);
};
export const hasAllPermission = (permissions) => {
    return permissions.every(hasPermission);
};
