/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type SiteSettingsFragmentFragment = { __typename?: 'SiteSettings', vatPercentage?: number | null, orderBaseDeliveryFee?: number | null, defaultCurrency: string, doctorVisitAcceptanceTimeoutSeconds?: number | null, healthProgramVisitWaitTimeBeforeCallSeconds?: number | null, messengerCallMinimumDurationSeconds?: number | null, appointmentReminderTimeSeconds?: number | null, prescriptionOrderPaymentTimeoutSeconds?: number | null, orderErxHubApprovalTimeoutAlert?: number | null, orderErxHubApprovalTimeout?: number | null, orderErxHubApprovalResubmitTimeout?: number | null, orderDispensingTimeoutAlert?: number | null, orderDispensingTimeout?: number | null, orderDeliveryTimeout?: number | null, orderAcceptanceTimeout?: number | null, multiplePharmaciesDelivery?: boolean | null, iosVersion?: number | null, androidVersion?: number | null, marketPlacePharmacyFirst?: boolean | null, vatPercentageForMedicalServices?: number | null };

export const SiteSettingsFragmentFragmentDoc = gql`
    fragment siteSettingsFragment on SiteSettings {
  vatPercentage
  orderBaseDeliveryFee
  defaultCurrency
  doctorVisitAcceptanceTimeoutSeconds
  healthProgramVisitWaitTimeBeforeCallSeconds
  messengerCallMinimumDurationSeconds
  appointmentReminderTimeSeconds
  prescriptionOrderPaymentTimeoutSeconds
  orderErxHubApprovalTimeoutAlert
  orderErxHubApprovalTimeout
  orderErxHubApprovalResubmitTimeout
  orderDispensingTimeoutAlert
  orderDispensingTimeout
  orderDeliveryTimeout
  orderAcceptanceTimeout
  multiplePharmaciesDelivery
  iosVersion
  androidVersion
  marketPlacePharmacyFirst
  vatPercentageForMedicalServices
}
    `;