import { HealthProgram, HealthProgramInput } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, PageWrapper, useBreadCrumbs } from "@toolkit/ui";
import {
  healthProgramFieldBreadcrumb,
  healthProgramListBreadcrumb,
  healthProgramProgramLogicBreadcrumb,
  healthProgramTitleBreadcrumb,
} from "pages/HealthProgram/constants/health-program-breadcrumbs";
import { healthProgramPaths } from "pages/HealthProgram/constants/health-program-paths";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHealthProgramEditHook } from "../edit/HealthProgramEdit.hook";
import { ProgramTrackingForm } from "./components/ProgramTrackingForm.component";
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";

export const ProgramTrackingPage: FC = () => {
  const { t } = useTranslation("admin");
  const canManageActions = hasPermission(PermissionEnum.ManageHealthPrograms);

  const { errors, healthProgram, loading, handleCancel, handleProgramTracking, isSubmitting } = useHealthProgramEditHook();
  const methods = useForm<HealthProgramInput>({
    mode: "onChange",
  });
  const { setBreadCrumb } = useBreadCrumbs();

  const { handleSubmit } = methods;

  useEffect(() => {
    if (healthProgram?.id && healthProgram?.name) {
      setBreadCrumb({
        title: healthProgramTitleBreadcrumb(t),
        values: [
          healthProgramListBreadcrumb(t),
          healthProgramFieldBreadcrumb(healthProgram?.name, healthProgramPaths.editPath.fullPathWithParams(healthProgram?.id)),
          healthProgramProgramLogicBreadcrumb(t),
        ],
      });
    }
  }, [healthProgram?.id, healthProgram?.name]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleProgramTracking)}>
        <PageWrapper
          actions={
            <FormActions
              isLoading={loading}
              hasCancel
              hasFormButton={canManageActions}
              formButtonTitle={t("Update")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <FormCard title={t("Points Factors And Rates")} loading={loading} doYouHaveData>
            <ProgramTrackingForm
              readOnly={!canManageActions}
              errors={errors}
              healthProgram={healthProgram as HealthProgram}
              loading={loading}
            />
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
