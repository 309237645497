import { H_EntityError } from "@health/queries/types";
import { combineErrors, getRequiredValidation, validateValueIsNotEmpty } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, TextField, ToggleButtonController } from "@toolkit/ui";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Payers } from "shared/components";
import { formButtonTitle } from "shared/utils";
import { NetworkProvidersList } from "./NetworkProvidersList";
import { healthProgramNetworkProps } from "./types";

export const HealthProgramNetworkForm: FC<healthProgramNetworkProps> = props => {
  const { defaultValue, isLoading, errors, onDone, isSubmitting } = props;
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const params = useParams();

  const { ...methods } = useForm();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors: reactFormErrors },
  } = methods;

  const formErrors = combineErrors(reactFormErrors, errors);

  const onSubmit = data => {
    onDone(data);
  };

  const handleCancel = () => {
    navigate(networksPaths.listPath.fullPath);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle(t, isLoading, defaultValue)}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData title={t("General Information")}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      placeholder={t("Name")}
                      label={t("Name")}
                      defaultValue={defaultValue?.name}
                      error={Boolean(formErrors.name?.message)}
                      helperText={t(formErrors.name?.message)}
                      {...register("name", {
                        required: getRequiredValidation(t, true),
                        validate: value => validateValueIsNotEmpty(value, t),
                      })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Payers<Partial<H_EntityError>> isRequired errors={errors!} payer={defaultValue?.payer!} />
                  </Grid>
                  <Grid item xs={2} display='flex' alignContent='center'>
                    <ToggleButtonController
                      control={control}
                      label={t("Is Active")}
                      defaultValue={defaultValue?.isActive}
                      {...register("isActive")}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
            {params?.id && (
              <Grid item xs={12}>
                <NetworkProvidersList />
              </Grid>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
