import { branchesKey } from "./constant";
const ISSERVER = typeof window === "undefined";
export function getToken() {
    var _a;
    return (_a = getUser()) === null || _a === void 0 ? void 0 : _a.access_token;
}
export function getUser() {
    var _a, _b, _c;
    try {
        return (!ISSERVER &&
            localStorage.getItem((_a = window === null || window === void 0 ? void 0 : window["getAuthLocalStorageKey"]) === null || _a === void 0 ? void 0 : _a.call(window)) &&
            ((_c = JSON.parse(localStorage.getItem((_b = window === null || window === void 0 ? void 0 : window["getAuthLocalStorageKey"]) === null || _b === void 0 ? void 0 : _b.call(window)))) === null || _c === void 0 ? void 0 : _c.profile));
    }
    catch (e) {
        return null;
    }
}
export function setBranches(branches) {
    !ISSERVER && localStorage.setItem(branchesKey, JSON.stringify(branches));
}
export function removeBranches() {
    !ISSERVER && localStorage.removeItem(branchesKey);
}
export function getBranches() {
    return !ISSERVER && localStorage.getItem(branchesKey) && JSON.parse(localStorage.getItem(branchesKey));
}
