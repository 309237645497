import { i18n } from "@toolkit/i18n";
import { PaymentType } from "@health/queries/types";
export const paymentTypeOptionsMap = {
    [PaymentType.Cd]: {
        key: PaymentType.Cd,
        get label() {
            return i18n.t("CD", { ns: "domains" });
        },
        value: PaymentType.Cd,
    },
    [PaymentType.Cp]: {
        key: PaymentType.Cp,
        get label() {
            return i18n.t("Cp", { ns: "domains" });
        },
        value: PaymentType.Cp,
    },
    [PaymentType.Db]: {
        key: PaymentType.Db,
        get label() {
            return i18n.t("Db", { ns: "domains" });
        },
        value: PaymentType.Db,
    },
    [PaymentType.Pa]: {
        key: PaymentType.Pa,
        get label() {
            return i18n.t("Pa", { ns: "domains" });
        },
        value: PaymentType.Pa,
    },
    [PaymentType.Rf]: {
        key: PaymentType.Rf,
        get label() {
            return i18n.t("Rf", { ns: "domains" });
        },
        value: PaymentType.Rf,
    },
    [PaymentType.Rv]: {
        key: PaymentType.Rv,
        get label() {
            return i18n.t("Rv", { ns: "domains" });
        },
        value: PaymentType.Rv,
    },
};
export const paymentTypeOptions = Object.values(paymentTypeOptionsMap);
