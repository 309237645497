import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { GlobeIcon } from "@toolkit/ui";
import { citiesManagementPaths, citiesManagementRoute } from "pages/CitiesManagement/constants/cities-management-paths";
import { CitiesManagementEditFormPage } from "pages/CitiesManagement/Edit";
import { CitiesManagementList } from "pages/CitiesManagement/List";
import { CitiesManagementNewFormPage } from "pages/CitiesManagement/New";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const citiesManagementRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }): RouteItem => {
  return {
    id: "cities-management-routes",
    text: i18n.t("Cities Management", { ns: "admin" }),
    icon: <GlobeIcon />,
    route: citiesManagementRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageCities),
    subItems: [
      {
        id: "cities-management-list-route",
        text: i18n.t("City Management", { ns: "admin" }),
        route: citiesManagementPaths.listPath.route,
        fullPath: citiesManagementPaths.listPath.fullPath,
        element: <CitiesManagementList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "cities-management-new-route",
        text: i18n.t("New City", { ns: "admin" }),
        route: citiesManagementPaths.newPath.route,
        fullPath: citiesManagementPaths.newPath.fullPath,
        hidden: true,
        element: <CitiesManagementNewFormPage />,
      },
      {
        id: "cities-management-edit-route",
        text: i18n.t("Edit City", { ns: "admin" }),
        route: citiesManagementPaths.editPath.route,
        fullPath: citiesManagementPaths.editPath.fullPath,
        hidden: true,
        element: <CitiesManagementEditFormPage />,
      },
    ],
  };
};
