import { MedicalMessageCategory } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const medicalMessageCategoryOptionsMap = {
    [MedicalMessageCategory.ActivityInstruction]: {
        key: MedicalMessageCategory.ActivityInstruction,
        get label() {
            return i18n.t("Activity Instruction", { ns: "domains" });
        },
        value: MedicalMessageCategory.ActivityInstruction,
    },
    [MedicalMessageCategory.General]: {
        key: MedicalMessageCategory.General,
        get label() {
            return i18n.t("General", { ns: "domains" });
        },
        value: MedicalMessageCategory.General,
    },
    [MedicalMessageCategory.LifestyleReminder]: {
        key: MedicalMessageCategory.LifestyleReminder,
        get label() {
            return i18n.t("Reminder", { ns: "domains" });
        },
        value: MedicalMessageCategory.LifestyleReminder,
    },
    [MedicalMessageCategory.Medical]: {
        key: MedicalMessageCategory.Medical,
        get label() {
            return i18n.t("Medical", { ns: "domains" });
        },
        value: MedicalMessageCategory.Medical,
    },
    [MedicalMessageCategory.MonitoringReminder]: {
        key: MedicalMessageCategory.MonitoringReminder,
        get label() {
            return i18n.t("Monitoring Reminder", { ns: "domains" });
        },
        value: MedicalMessageCategory.MonitoringReminder,
    },
};
export const medicalMessageCategoryOptions = Object.values(medicalMessageCategoryOptionsMap);
