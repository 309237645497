import { getMaxLengthValidation, patternEmail, validateValueIsNotEmpty } from "@toolkit/core";

export const generalInformationFields = (t, defaultValue, backendErrorStorage) => [
  {
    name: "name",
    label: t("Branch Name"),
    defaultValue: defaultValue?.name,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
      validate: value => backendErrorStorage["name"][value] || validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "nameAr",
    label: t("Arabic Branch Name"),
    defaultValue: defaultValue?.nameAr,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
      validate: value => backendErrorStorage["nameAr"][value] || validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "description",
    label: t("Description"),
    registerProps: {
      maxLength: getMaxLengthValidation(t, 2000),
      validate: value => backendErrorStorage["description"][value] || validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "contactEmail",
    label: t("Contact Email"),
    defaultValue: defaultValue?.contactEmail,
    pattern: patternEmail(t),
    validate: value => backendErrorStorage["contactEmail"][value] || validateValueIsNotEmpty(value, t),
  },
  {
    name: "healthLicense",
    label: t("Health License"),
    defaultValue: defaultValue?.healthLicense,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
      validate: value => backendErrorStorage["healthLicense"][value] || validateValueIsNotEmpty(value, t),
    },
  },
];
