import { FileDownload, FileImportDialog } from "@health/domains";
import { BulkTemplateCode } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, GridContext, TableGrid, Typography, useTheme } from "@toolkit/ui";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import useToggle from "shared/hooks/useToggle";
import { useHealthProgramNetworkProvidersColumns, useHealthProgramNetworkProvidersQuery } from "../../HealthProgramNetworkProviders";

export const NetworkProvidersList = () => {
  const params = useParams();
  const { t } = useTranslation("admin");

  const { open, handleToggle } = useToggle();

  const handleCompleted = data => {
    !data?.healthProgramNetworkProviders?.edges?.length && handleToggle();
  };
  const theme = useTheme();
  return (
    <Box bgcolor={theme.palette.common.white} borderRadius='10px'>
      <GridProvider
        variables={{ filter: { healthProgramNetworks: [params?.id!] } }}
        gridName='healthProgramNetworks'
        columns={useHealthProgramNetworkProvidersColumns({ healthProgramNetworkID: params?.id })}
        query={useHealthProgramNetworkProvidersQuery}
        tableAction={{
          isEditVisible: false,
          isDeleteVisible: false,
        }}
        onCompleted={handleCompleted}
        skipCall={!params?.id}
      >
        <GridContext.Consumer>
          {({ customTableProps }) => {
            const handleSuccessUpload = () => {
              customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
            };
            return (
              <div>
                <Box display='flex' justifyContent='space-between' m={1} alignItems='center'>
                  <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{t("Providers")}</Typography>
                  <div>
                    <FileImportDialog
                      title={t("Import Providers")}
                      subTitle={t("Upload Providers list you want to get")}
                      isOpen={open}
                      onSuccessUpload={handleSuccessUpload}
                      onToggle={handleToggle}
                      templateCode={BulkTemplateCode.HealthProgramNetworkProvider}
                    />

                    <FileDownload templateCode={BulkTemplateCode.HealthProgramNetworkProvider} />
                  </div>
                </Box>
                <TableGrid />
              </div>
            );
          }}
        </GridContext.Consumer>
      </GridProvider>
    </Box>
  );
};
