import { useState } from "react";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useCustomerSupportErxOrderStatusUpdateMutation } from "../../../gql";
export const useHandleOrder = (reset, id) => {
    const { t } = useTranslation("domains");
    const [errors, setErrors] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { succeeded, failed } = useAddToast();
    const [handelOrder, { loading }] = useCustomerSupportErxOrderStatusUpdateMutation({
        onCompleted: (mutationData) => {
            var _a;
            const error = (_a = mutationData === null || mutationData === void 0 ? void 0 : mutationData.customerSupportErxOrderStatusUpdate) === null || _a === void 0 ? void 0 : _a.orderErrors;
            if (error && (error === null || error === void 0 ? void 0 : error.length) > 0) {
                failed(t(formatMessageErrors(error)));
                setErrors(error);
            }
            else {
                succeeded(t("Order handled successfully"));
                setIsOpen(false);
                reset();
            }
        },
        onError: () => {
            failed(t("Failed in handling order"));
        },
    });
    const handleToggleDialog = () => {
        setIsOpen(!isOpen);
    };
    const handleOpen = e => {
        e.stopPropagation();
        setIsOpen(true);
    };
    const handleUpdate = inputData => {
        var _a;
        handelOrder({
            variables: {
                customerSupportErxOrderStatusUpdateId: id,
                status: (_a = inputData === null || inputData === void 0 ? void 0 : inputData.status) === null || _a === void 0 ? void 0 : _a.value,
            },
        });
    };
    return { handleUpdate, errors, loading, handleToggleDialog, handleOpen, isOpen };
};
