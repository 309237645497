import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast, useModal } from "@toolkit/ui";
import React, { FC } from "react";
import { usePayerUpdateMutation } from "../../gql";
type ActiveToggleProps = {
  id: string;
  isActive: boolean;
};

export const PayerActiveToggleButton: FC<ActiveToggleProps> = ({ id, isActive }) => {
  const { t } = useTranslation("admin");
  const [checked, setChecked] = React.useState(isActive);
  const { succeeded, failed } = useAddToast();
  const { open, handleToggle } = useModal();
  const [updateUser, { loading }] = usePayerUpdateMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const errors = data?.payerUpdate?.payerErrors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(error);
      } else {
        setChecked(!!data?.payerUpdate?.payer?.isActive);
        succeeded(t("Payer Updated Successfully"));
      }
    },
    onError: () => {
      failed(t("Payer Update Failed"));
    },
  });

  const handleChangeActivity = () => {
    if (loading) return;
    updateUser({
      variables: {
        payerUpdateId: id,
        input: {
          isActive: !checked,
        },
      },
    });
  };

  return (
    <>
      <CustomToggleButton checked={checked} onChange={handleToggle} isLoading={loading} />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: checked ? t("Are you sure you want to deactivate this payer?") : t("Are you sure you want to activate this payer?"),
            title: checked ? t("Deactivate Payer Confirmation") : t("Activate Payer Confirmation"),
          }}
          onClose={handleToggle}
          onConfirm={handleChangeActivity}
        />
      )}
    </>
  );
};
