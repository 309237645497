import { ApolloQueryResult, NetworkStatus } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { IRelayStyleConnectionArgs, IRelayStyleConnectionReturn } from "../types";

export const useRelayStyleConnection = <QueryVariables, ReturnValue>(
  props: IRelayStyleConnectionArgs<QueryVariables>
): IRelayStyleConnectionReturn<QueryVariables, ReturnValue> => {
  const { dataAccessor, useQuery, variables, skip, context } = props;

  const {
    data: response,
    loading,
    networkStatus,
    refetch,
    fetchMore,
    ...queryResult
  } = useQuery({
    variables: useMemo(() => ({ first: 10, ...variables }), [variables]),
    skip,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context,
  });

  const responseDataAccessor = response?.[dataAccessor];
  const data = responseDataAccessor?.edges?.map((item: { node: ReturnValue }) => item?.node);

  const hasMoreData = responseDataAccessor?.pageInfo?.hasNextPage;
  const isLoading = loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  const fetchMoreData = useCallback(() => {
    if (hasMoreData && !isLoading) {
      fetchMore({
        variables: {
          ...variables,
          after: responseDataAccessor?.pageInfo?.endCursor,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousQueryResult;
          }

          return {
            ...fetchMoreResult,
            [dataAccessor]: {
              ...fetchMoreResult?.[dataAccessor],
              edges: [...(previousQueryResult?.[dataAccessor]?.edges || []), ...(fetchMoreResult?.[dataAccessor]?.edges || [])],
            },
          };
        },
      });
    }
  }, [fetchMore, hasMoreData, loading, variables]);

  const handleRefetch = async (_variables?: Partial<QueryVariables>): Promise<ApolloQueryResult<ReturnValue>> => {
    if (skip) {
      return Promise.resolve(response as ApolloQueryResult<ReturnValue>);
    } else {
      return await refetch(_variables);
    }
  };

  return {
    data,
    isLoading,
    hasMoreData,
    refetch: handleRefetch,
    fetchMoreData,
    ...queryResult,
  };
};
