/* eslint-disable max-lines */
import { CodeSystemCode } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { pick } from "lodash";
export const codeSystemCodeOptionsMap = {
    [CodeSystemCode.Allergy]: {
        key: CodeSystemCode.Allergy,
        get label() {
            return i18n.t("Allergy", { ns: "domains" });
        },
        value: CodeSystemCode.Allergy,
    },
    [CodeSystemCode.ChronicDisease]: {
        key: CodeSystemCode.ChronicDisease,
        get label() {
            return i18n.t("Chronic Disease", { ns: "domains" });
        },
        value: CodeSystemCode.ChronicDisease,
    },
    [CodeSystemCode.ChronicMedication]: {
        key: CodeSystemCode.ChronicMedication,
        get label() {
            return i18n.t("Chronic Medication", { ns: "domains" });
        },
        value: CodeSystemCode.ChronicMedication,
    },
    [CodeSystemCode.City]: {
        key: CodeSystemCode.City,
        get label() {
            return i18n.t("City", { ns: "domains" });
        },
        value: CodeSystemCode.City,
    },
    [CodeSystemCode.Clinician]: {
        key: CodeSystemCode.Clinician,
        get label() {
            return i18n.t("Clinician", { ns: "domains" });
        },
        value: CodeSystemCode.Clinician,
    },
    [CodeSystemCode.Cpt]: {
        key: CodeSystemCode.Cpt,
        get label() {
            return i18n.t("CPT", { ns: "domains" });
        },
        value: CodeSystemCode.Cpt,
    },
    [CodeSystemCode.DenialCode]: {
        key: CodeSystemCode.DenialCode,
        get label() {
            return i18n.t("Denial Code", { ns: "domains" });
        },
        value: CodeSystemCode.DenialCode,
    },
    [CodeSystemCode.Diagnosis]: {
        key: CodeSystemCode.Diagnosis,
        get label() {
            return i18n.t("Diagnosis", { ns: "domains" });
        },
        value: CodeSystemCode.Diagnosis,
    },
    [CodeSystemCode.DiagnosisRelatedGroup]: {
        key: CodeSystemCode.DiagnosisRelatedGroup,
        get label() {
            return i18n.t("Diagnosis Related Group", { ns: "domains" });
        },
        value: CodeSystemCode.DiagnosisRelatedGroup,
    },
    [CodeSystemCode.Disability]: {
        key: CodeSystemCode.Disability,
        get label() {
            return i18n.t("Disability", { ns: "domains" });
        },
        value: CodeSystemCode.Disability,
    },
    [CodeSystemCode.Division]: {
        key: CodeSystemCode.Division,
        get label() {
            return i18n.t("Division", { ns: "domains" });
        },
        value: CodeSystemCode.Division,
    },
    [CodeSystemCode.Family]: {
        key: CodeSystemCode.Family,
        get label() {
            return i18n.t("Family", { ns: "domains" });
        },
        value: CodeSystemCode.Family,
    },
    [CodeSystemCode.GenericDrug]: {
        key: CodeSystemCode.GenericDrug,
        get label() {
            return i18n.t("Generic Drug", { ns: "domains" });
        },
        value: CodeSystemCode.GenericDrug,
    },
    [CodeSystemCode.GenericDrugExtraData]: {
        key: CodeSystemCode.GenericDrugExtraData,
        get label() {
            return i18n.t("Generic Drug Extra Data", { ns: "domains" });
        },
        value: CodeSystemCode.GenericDrugExtraData,
    },
    [CodeSystemCode.HealthPackageCategory]: {
        key: CodeSystemCode.HealthPackageCategory,
        get label() {
            return i18n.t("Health Package Category", { ns: "domains" });
        },
        value: CodeSystemCode.HealthPackageCategory,
    },
    [CodeSystemCode.HealthProfessionalSpeciality]: {
        key: CodeSystemCode.HealthProfessionalSpeciality,
        get label() {
            return i18n.t("Health Professional Speciality", { ns: "domains" });
        },
        value: CodeSystemCode.HealthProfessionalSpeciality,
    },
    [CodeSystemCode.HealthProvider]: {
        key: CodeSystemCode.HealthProvider,
        get label() {
            return i18n.t("Health Provider", { ns: "domains" });
        },
        value: CodeSystemCode.HealthProvider,
    },
    [CodeSystemCode.LabTest]: {
        key: CodeSystemCode.LabTest,
        get label() {
            return i18n.t("Lab Test", { ns: "domains" });
        },
        value: CodeSystemCode.LabTest,
    },
    [CodeSystemCode.Loinc]: {
        key: CodeSystemCode.Loinc,
        get label() {
            return i18n.t("Loinc", { ns: "domains" });
        },
        value: CodeSystemCode.Loinc,
    },
    [CodeSystemCode.MedicalService]: {
        key: CodeSystemCode.MedicalService,
        get label() {
            return i18n.t("Medical Service", { ns: "domains" });
        },
        value: CodeSystemCode.MedicalService,
    },
    [CodeSystemCode.MobileTranslation]: {
        key: CodeSystemCode.MobileTranslation,
        get label() {
            return i18n.t("Mobile Translation", { ns: "domains" });
        },
        value: CodeSystemCode.MobileTranslation,
    },
    [CodeSystemCode.NonChronicDisease]: {
        key: CodeSystemCode.NonChronicDisease,
        get label() {
            return i18n.t("Non Chronic Disease", { ns: "domains" });
        },
        value: CodeSystemCode.NonChronicDisease,
    },
    [CodeSystemCode.RejectionReason]: {
        key: CodeSystemCode.RejectionReason,
        get label() {
            return i18n.t("Rejection Reason", { ns: "domains" });
        },
        value: CodeSystemCode.RejectionReason,
    },
    [CodeSystemCode.RouteOfAdmin]: {
        key: CodeSystemCode.RouteOfAdmin,
        get label() {
            return i18n.t("Route of Admin", { ns: "domains" });
        },
        value: CodeSystemCode.RouteOfAdmin,
    },
    [CodeSystemCode.Service]: {
        key: CodeSystemCode.Service,
        get label() {
            return i18n.t("Service", { ns: "domains" });
        },
        value: CodeSystemCode.Service,
    },
    [CodeSystemCode.Speciality]: {
        key: CodeSystemCode.Speciality,
        get label() {
            return i18n.t("Speciality", { ns: "domains" });
        },
        value: CodeSystemCode.Speciality,
    },
    [CodeSystemCode.Surgery]: {
        key: CodeSystemCode.Surgery,
        get label() {
            return i18n.t("Surgery", { ns: "domains" });
        },
        value: CodeSystemCode.Surgery,
    },
    [CodeSystemCode.Temperature]: {
        key: CodeSystemCode.Temperature,
        get label() {
            return i18n.t("Temperature", { ns: "domains" });
        },
        value: CodeSystemCode.Temperature,
    },
    [CodeSystemCode.TradeDrug]: {
        key: CodeSystemCode.TradeDrug,
        get label() {
            return i18n.t("Trade Drug", { ns: "domains" });
        },
        value: CodeSystemCode.TradeDrug,
    },
    [CodeSystemCode.TradeDrugExtraData]: {
        key: CodeSystemCode.TradeDrugExtraData,
        get label() {
            return i18n.t("Trade Drug Extra Data", { ns: "domains" });
        },
        value: CodeSystemCode.TradeDrugExtraData,
    },
    [CodeSystemCode.UnitId]: {
        key: CodeSystemCode.UnitId,
        get label() {
            return i18n.t("Unit Id", { ns: "domains" });
        },
        value: CodeSystemCode.UnitId,
    },
    [CodeSystemCode.CancellationReason]: {
        key: CodeSystemCode.CancellationReason,
        get label() {
            return i18n.t("Cancellation Reason", { ns: "domains" });
        },
        value: CodeSystemCode.CancellationReason,
    },
    [CodeSystemCode.Nationality]: {
        key: CodeSystemCode.Nationality,
        get label() {
            return i18n.t("Nationality", { ns: "domains" });
        },
        value: CodeSystemCode.Nationality,
    },
};
export const codeSystemCodeOptions = Object.values(codeSystemCodeOptionsMap);
export const serviceSystemCodeOptions = Object.values(pick(codeSystemCodeOptionsMap, [CodeSystemCode.Cpt, CodeSystemCode.Service]));
