/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { healthPackageCategoriesPaths, healthPackageCategoriesRoute } from "./CategoriesPaths";
import { CategoriesListContainer } from "../containers";
import { CategoryUpdateContainer } from "../containers/CategoryUpdate/CategoryUpdateContainer";
import { CategoryCreateContainer } from "../containers/CategoryCerate/CategoryCreateContainer";
import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@health/common";

export const healthPackageCategoriesRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  enabled,
}) => {
  return {
    id: "marketplace-healthPackage-healthPackage-categories",
    text: i18n.t("health Package Categories", { ns: "admin" }),
    route: healthPackageCategoriesRoute,
    hidden: !enabled || !hasPermission(PermissionEnum.ManageHealthPackageCategories),
    isProhibited: !enabled || !hasPermission(PermissionEnum.ManageHealthPackageCategories),
    subItems: [
      {
        id: "healthPackageCategories-list",
        text: i18n.t("Categories", { ns: "admin" }),
        route: healthPackageCategoriesPaths.main.route,
        fullPath: healthPackageCategoriesPaths.main.fullPath,
        element: <CategoriesListContainer />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "healthPackage-category-new",
        text: i18n.t("New", { ns: "admin" }),
        element: <CategoryCreateContainer />,
        route: healthPackageCategoriesPaths.new.route,
        fullPath: healthPackageCategoriesPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "healthPackage-category-update",
        text: i18n.t("Update", { ns: "admin" }),
        element: <CategoryUpdateContainer />,
        route: healthPackageCategoriesPaths.update.route,
        fullPath: healthPackageCategoriesPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "subcategories-list",
        text: i18n.t("Subcategories List", { ns: "admin" }),
        route: healthPackageCategoriesPaths.healthPackageCategoryCategories.route,
        fullPath: healthPackageCategoriesPaths.healthPackageCategoryCategories.fullPath,
        element: <CategoriesListContainer hasParent />,
        hidden: true,
        isProhibited: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "subcategories-new",
        text: i18n.t("Subcategories New", { ns: "admin" }),
        route: healthPackageCategoriesPaths.newSubcategory.route,
        fullPath: healthPackageCategoriesPaths.newSubcategory.fullPath,
        element: <CategoryCreateContainer hasParent />,
        hidden: true,
        isProhibited: !enabled,
        onClick: route => navigate(route),
      },
    ],
  };
};
