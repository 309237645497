import React, { FC, PropsWithChildren } from "react";
import { AppThemeProvider } from "../customization";
import { AppToastProvider } from "./AppToastProvider";

export const ToolkitUiProvider: FC<PropsWithChildren<{ theme?: "iohealth" | "digital-twin" }>> = ({ children, theme }) => {
  return (
    <AppThemeProvider theme={theme}>
      <AppToastProvider>{children}</AppToastProvider>
    </AppThemeProvider>
  );
};
