import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { MenuItem, Typography, UploadIcon, useAddToast } from "@toolkit/ui";
import { usePublishCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const PublishButton = ({ onClick }) => {
    const { t } = useTranslation("domains");
    const { failed, succeeded } = useAddToast();
    const { refetchCodeSystem, versionParamId } = useAdminManagedListDetailsPageContext();
    const [publishCodeSystem, { loading }] = usePublishCodeSystemMutation({
        onCompleted: data => {
            var _a, _b;
            const message = (_a = data === null || data === void 0 ? void 0 : data.publishCodeSystem) === null || _a === void 0 ? void 0 : _a.errors;
            if (message === null || message === void 0 ? void 0 : message.length) {
                failed((_b = message[0]) === null || _b === void 0 ? void 0 : _b.message);
            }
            else {
                succeeded(t("Data published successfully"));
            }
        },
        onError: error => {
            failed(t(error === null || error === void 0 ? void 0 : error.message));
        },
    });
    const handleClick = () => {
        onClick();
        publishCodeSystem({
            variables: {
                codeSystemId: versionParamId,
            },
        }).then(() => {
            refetchCodeSystem === null || refetchCodeSystem === void 0 ? void 0 : refetchCodeSystem();
        });
    };
    return (_jsxs(MenuItem, Object.assign({ onClick: handleClick, disabled: loading }, { children: [_jsx(UploadIcon, { color: "primary" }), _jsxs(Typography, Object.assign({ marginLeft: '5px' }, { children: [" ", t("Publish")] }))] })));
};
