import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProcessingStatus } from "@health/queries/types";
import { Box, FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useCallback } from "react";
import { GridProvider } from "../../../shared/components";
import { useDeleteCodeSystemConceptMutation, useGetCodeSystemConceptsQuery } from "../gql";
import { AdminManagedListDetailsPageContext } from "./AdminManagedListDetailsPageContext";
import { AMLStatusSummary } from "./components/AMLStatusSummary";
import { ActionsMenu } from "./components/ActionsMenu/ActionsMenu";
import StatusAlertsPanel from "./components/StatusAlertsPanel.component";
import { VersionsList } from "./components/VersionsList/VersionsList";
import { useAdminManagedListDetailsPage } from "./useAdminManagedListDetailsPageHook";
/**
 * this page lists concept properties of a code system version,
 * @param {CodeSystemCode} code => CodeSystemCode
 * @param {string} id ==> version id
 * @returns t
 */
const AdminManagedListDetailsPage = () => {
    const pageContextValue = useAdminManagedListDetailsPage();
    const { columnsDefinition: columnsDefinition, id, versionNumber, processingStatus, reviewerUserName, handleEditRow } = pageContextValue;
    const query = useCallback(hookBasicOptions => {
        return useGetCodeSystemConceptsQuery(Object.assign(Object.assign({}, hookBasicOptions), { variables: Object.assign(Object.assign({}, hookBasicOptions.variables), { filter: undefined, codeSystemId: id, searchParameters: Object.values(hookBasicOptions.variables.filter || {}) }), skip: !id || id == null || id == "new" }));
    }, [id]);
    return (_jsx(GridProvider, Object.assign({ hasTableSetting: true, gridName: "getCodeSystemConcepts", columns: columnsDefinition, query: query, skipCall: !id || id == null || id == "new", tableAction: {
            isDeleteVisible: processingStatus === ProcessingStatus.Draft,
            isEditVisible: processingStatus === ProcessingStatus.Draft,
            onEditRow: handleEditRow,
        }, deleteItemProps: {
            name: "Item",
            idPropName: "codeSystemConceptId",
            entityTypeName: "CodeSystemConcept",
            useDeleteMutation: useDeleteCodeSystemConceptMutation,
        } }, { children: _jsx(AdminManagedListDetailsPageContext.Provider, Object.assign({ value: pageContextValue }, { children: _jsxs(PageWrapper, Object.assign({ filters: _jsx(FilterGrid, { ignoreTranslations: true, isDynamicFilters: true }), actions: _jsx(Box, { children: _jsxs(Box, Object.assign({ display: "inline-flex", flexWrap: "nowrap", alignItems: 'center' }, { children: [_jsx(TableSettingComponent, {}), _jsx(VersionsList, {}), _jsx(ActionsMenu, {})] })) }) }, { children: [_jsx(Box, Object.assign({ mt: '-40px', pt: "12px", pb: "12px" }, { children: _jsx(AMLStatusSummary, { processingStatus: processingStatus }) })), processingStatus && (_jsx(StatusAlertsPanel, { processingStatus: processingStatus, versionNumber: versionNumber, userName: reviewerUserName })), _jsx(TableGrid, {})] })) })) })));
};
export default AdminManagedListDetailsPage;
