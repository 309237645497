import { useCallback, useState } from "react";

const useToggle = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen(open => !open);
  }, []);

  return { open, handleToggle };
};

export default useToggle;
