import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, MenuItem, Typography } from "@toolkit/ui";
import { AMLAssignReviewerDialog } from "../../../shared/AMLAssignReviewerDialog";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const AssignButton = ({ onClick }) => {
    const { code, reviewerUser, editorUser } = useAdminManagedListDetailsPageContext();
    const { t } = useTranslation("domains");
    return (_jsx(AMLAssignReviewerDialog, { code: code, reviewerUser: reviewerUser, editorUser: editorUser, renderButton: openDialog => {
            const handleClick = () => {
                openDialog();
                onClick();
            };
            return (_jsxs(MenuItem, Object.assign({ onClick: handleClick }, { children: [_jsx(CustomIcon, { icon: 'correct', viewBox: '0 0 20 20' }), _jsxs(Typography, Object.assign({ marginLeft: '2px' }, { children: [" ", (editorUser === null || editorUser === void 0 ? void 0 : editorUser.id) || (reviewerUser === null || reviewerUser === void 0 ? void 0 : reviewerUser.id) ? t("Reassign") : t("Assign")] }))] })));
        } }));
};
