import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OrdersLifeCycleDocument = gql `
    query ordersLifeCycle($first: Int, $after: String, $filter: OrderFilterInput) {
  orders(first: $first, after: $after, filter: $filter) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        number
        status
        failReason
        helpDiskTicketId
        total {
          gross {
            amount
            currency
          }
        }
        branch {
          name
          nameAr
        }
        events {
          id
          date
          type
        }
        children {
          id
          branch {
            name
            nameAr
          }
          number
          status
          type
          created
          events {
            id
            date
            type
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;
/**
 * __useOrdersLifeCycleQuery__
 *
 * To run a query within a React component, call `useOrdersLifeCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersLifeCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersLifeCycleQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersLifeCycleQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrdersLifeCycleDocument, options);
}
export function useOrdersLifeCycleLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrdersLifeCycleDocument, options);
}
