import { getEnvVariable } from "@health/env";
import { BulkProcessingStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, ExcelIcon, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useDownloadBulkFileLazyQuery } from "./gql";
import { useAuth } from "react-oidc-context";

export const DownloadBulkFile: FC<{ isButton: boolean; title: string; id: string; processingStatus: BulkProcessingStatus }> = ({
  isButton,
  title,
  id,
  processingStatus,
}) => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [downloadFile, { loading: isDownloading }] = useDownloadBulkFileLazyQuery();
  const { user } = useAuth();
  const handleGetTemplate = () => {
    downloadFile({
      variables: {
        fileId: id,
      },
    })
      .then(e => {
        if (e.data?.downloadBulkFile) {
          const url = getEnvVariable("SERVER_URL") + e.data?.downloadBulkFile;
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${user?.access_token}`);

          fetch(url, {
            method: "GET",
            headers: myHeaders,
          })
            .then(response => response.blob())
            .then(blob => {
              const blobUrl = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = blobUrl;
              link.download = e?.data?.downloadBulkFile?.split("/").pop() || "downloaded-file";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              succeeded(t("File downloaded successfully"));
            })
            .catch(() => failed(t("Failed to download file")));
        } else {
          failed(t("Failed to download file"));
        }
      })
      .catch(() => failed(t("Failed to download list")));
  };

  const theme = useTheme();
  return !isButton ? (
    <Typography
      fontSize={theme.mixins.fonts.fontSize.sm}
      sx={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={handleGetTemplate}
    >
      {title}
    </Typography>
  ) : (
    <Button
      sx={{ paddingInline: 6, borderRadius: "10px", whiteSpace: "nowrap" }}
      startIcon={<ExcelIcon />}
      disabled={processingStatus == BulkProcessingStatus.Pending || processingStatus == BulkProcessingStatus.Cancelled || isDownloading}
      onClick={handleGetTemplate}
    >
      {title}
    </Button>
  );
};
