import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { ExcelIcon, MenuItem, Typography, useAddToast, useModal, useTheme } from "@toolkit/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminManagedListsPaths } from "../../../constants";
import { useUploadCodeSystemFileMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
import { UploadDialog } from "../../../../../shared/components";
export const ImportConceptsDialog = ({ onClick }) => {
    const navigate = useNavigate();
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const { code } = useAdminManagedListDetailsPageContext();
    const [dialogLoading, setDialogLoading] = useState(false);
    const [hasUploadFileErrors, setHasUploadFileErrors] = useState(false);
    const [errorUploadMessage, setErrorUploadMessage] = useState("");
    const { open: isOpen, handleClose, handleOpen } = useModal();
    const [uploadCodeSystemFile] = useUploadCodeSystemFileMutation({
        onCompleted: data => {
            var _a, _b;
            setDialogLoading(false);
            succeeded(t("Excel file has been imported successfully"));
            onDialogClose();
            if ((_a = data.uploadCodeSystemFile) === null || _a === void 0 ? void 0 : _a.id) {
                navigate(adminManagedListsPaths.detailsPath.fullPathWithParams(code, (_b = data.uploadCodeSystemFile) === null || _b === void 0 ? void 0 : _b.id));
                handleClose();
            }
        },
        onError: error => {
            setDialogLoading(false);
            setHasUploadFileErrors(true);
            setErrorUploadMessage(error === null || error === void 0 ? void 0 : error.message);
            failed(t("Import Failed"));
        },
    });
    const handleUploadCodeSystemFile = file => {
        setDialogLoading(true);
        setHasUploadFileErrors(false);
        uploadCodeSystemFile({
            variables: {
                codeSystemCode: code,
                file,
            },
        });
    };
    const onDialogClose = () => {
        handleClose();
        setHasUploadFileErrors(false);
        setErrorUploadMessage("");
    };
    const onResetErrorMessage = () => {
        setHasUploadFileErrors(false);
        setErrorUploadMessage("");
    };
    const handleClick = () => {
        handleOpen();
        onClick();
    };
    const { palette: { primary }, } = useTheme();
    return (_jsxs(_Fragment, { children: [_jsxs(MenuItem, Object.assign({ onClick: handleClick }, { children: [_jsx(ExcelIcon, { fill: primary.main }), _jsxs(Typography, Object.assign({ marginLeft: '8px' }, { children: [" ", t("Import"), " .xlsx"] }))] })), _jsx(UploadDialog, { isDialogLoading: dialogLoading, isOpen: isOpen, onFileUploadedSuccessfully: handleUploadCodeSystemFile, handleClose: onDialogClose, handleResetError: onResetErrorMessage, hasErrors: hasUploadFileErrors, errorMessage: errorUploadMessage, title: t("Upload list"), subTitle: t("Upload Med List You Want To Get") })] }));
};
