import { NetworkStatus } from "@apollo/client";
import { getEnvVariable } from "@health/env";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminManagedListsPaths } from "../../../constants";
import { useDownloadCodeSystemFileLazyQuery, useGetCodeSystemVersionsLazyQuery, usePublishCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const useVersionsListHooks = () => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const { code, id: versionParamId, refetchCodeSystem } = useAdminManagedListDetailsPageContext();
    const [isVersionsOpened, setIsVersionsOpened] = useState(false);
    const { succeeded, failed } = useAddToast();
    const [fetchGetVersions, { data: versionResponseData, loading: versionDataLoading, networkStatus, fetchMore: fetchMoreVersions, refetch: refetchVersions },] = useGetCodeSystemVersionsLazyQuery();
    const [publishCodeSystem, { loading: isPublishing }] = usePublishCodeSystemMutation({
        onCompleted: data => {
            var _a, _b;
            const message = (_a = data === null || data === void 0 ? void 0 : data.publishCodeSystem) === null || _a === void 0 ? void 0 : _a.errors;
            if (message === null || message === void 0 ? void 0 : message.length) {
                failed((_b = message[0]) === null || _b === void 0 ? void 0 : _b.message);
            }
            else {
                succeeded(t("Data published successfully"));
            }
        },
        onError: error => {
            failed(t(error === null || error === void 0 ? void 0 : error.message));
        },
    });
    const [getDownloadCodeSystemFile] = useDownloadCodeSystemFileLazyQuery({
        fetchPolicy: "network-only",
        onCompleted: data => {
            if (data === null || data === void 0 ? void 0 : data.downloadCodeSystemFile) {
                window.open(getEnvVariable("SERVER_URL", process.env.REACT_APP_SERVER_URL) + data.downloadCodeSystemFile, "_self");
            }
        },
    });
    const versionData = (_b = (_a = versionResponseData === null || versionResponseData === void 0 ? void 0 : versionResponseData.getCodeSystems) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(item => item === null || item === void 0 ? void 0 : item.node);
    const hasNextPageVersions = (_d = (_c = versionResponseData === null || versionResponseData === void 0 ? void 0 : versionResponseData.getCodeSystems) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.hasNextPage;
    const latestApprovedVersionNumber = (_e = versionData === null || versionData === void 0 ? void 0 : versionData.find(item => (item === null || item === void 0 ? void 0 : item.processingStatus) === "APPROVED")) === null || _e === void 0 ? void 0 : _e.version;
    const handleOnFetchMoreVersions = () => {
        var _a, _b;
        fetchMoreVersions({
            variables: {
                first: 10,
                after: (_b = (_a = versionResponseData === null || versionResponseData === void 0 ? void 0 : versionResponseData.getCodeSystems) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.endCursor,
            },
        });
    };
    const handleChangeActiveVersion = (versionId) => {
        versionId && onVersionActiveChange(versionId);
        setIsVersionsOpened(false);
    };
    const handleVersionDialogOpen = () => {
        fetchGetVersions({
            variables: {
                first: 10,
                code: code,
            },
        });
    };
    const onVersionActiveChange = (id) => {
        publishCodeSystem({
            variables: {
                codeSystemId: id,
            },
        }).then(() => {
            refetchCodeSystem === null || refetchCodeSystem === void 0 ? void 0 : refetchCodeSystem();
            refetchVersions();
        });
    };
    const handleDownloadFileFromVersion = (fileId) => {
        getDownloadCodeSystemFile({
            variables: {
                code: code,
                codeSystemId: fileId,
            },
        });
    };
    const handleToggleVersions = () => {
        if (!isVersionsOpened) {
            handleVersionDialogOpen();
        }
        setIsVersionsOpened(!isVersionsOpened);
    };
    const handleOpenVersionClick = (id, versionNumber) => {
        navigate(`${adminManagedListsPaths.detailsPath.fullPathWithParams(code, id)}?${versionNumber ? "v=" + versionNumber : ""}`);
        setIsVersionsOpened(false);
    };
    return {
        versionData,
        isPublishing,
        versionParamId,
        isVersionsOpened,
        versionDataLoading,
        hasNextPageVersions,
        latestApprovedVersionNumber,
        isLoadingVersions: networkStatus === NetworkStatus.loading,
        isRefetchingVersions: networkStatus === NetworkStatus.refetch,
        isFetchingMoreVersions: networkStatus === NetworkStatus.fetchMore,
        t,
        handleToggleVersions,
        handleChangeActiveVersion,
        handleOnFetchMoreVersions,
        handleDownloadFileFromVersion,
        handleVersionDialogOpen,
        handleOpenVersionClick,
    };
};
